import React from "react";
import PropTypes from "prop-types";
import ReactSelect, {components} from "react-select";
import ControlWrapper from './select/OptionWrapper';
import {FixedSizeList as List} from "react-window";
import {Trans} from "@lingui/macro";

/**
 * @fero
 */

const themeFunc = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary75: '#ffd9a1',
        primary25: '#ffd9a1',
        primary50: '#ffb347',
        primary: '#ffb347',
    },
    spacing: {
        ...theme.spacing,
        controlHeight: 20,
        menuGutter: 5,
    }
});

const customStyles = (height) => ({
    control: (base) => ({
        ...base,
        minHeight: '30px',
        padding: '1px',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 0,
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: 0,
    }),
    valueContainer: (base) => ({
        ...base,
        minHeight: height,
    }),
});

const createFilterFunc = (compareFunc) => (item, search) => {
    if (search == null || search == "") {
        return true;
    }
    if (item == null || item.label == null) {
        return false;
    }
    if (item.value == null) {
        return true;
    }
    return compareFunc(item, search);
};

const optionHeight = 35;

class Select extends React.PureComponent {
    static propTypes = {
        value: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired])),
            PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired])
        ]),
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
                label: PropTypes.node.isRequired,
            }).isRequired
        ).isRequired,
        placeholder: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string.isRequired]),
        maxMenuHeight: PropTypes.number,
        height: PropTypes.number,
        allowClear: PropTypes.bool,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        tooltiped: PropTypes.bool,
        isMulti: PropTypes.bool,
        selectAllOption: PropTypes.node,
        autoFocus: PropTypes.bool,
        isSearchable: PropTypes.bool,
        compareFunc: PropTypes.func,
        isOptionDisabled: PropTypes.func,
        currentOption: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
            label: PropTypes.node.isRequired,
        }),
        fixedOptions: PropTypes.array,
        className: PropTypes.string,
        autocomplete: PropTypes.string,
        after: PropTypes.node,
    };

    static defaultProps = {
        maxMenuHeight: 300,
        allowClear: false,
        disabled: false,
        tooltiped: true,
        isMulti: false,
        allowSelectAll: false,
        autoFocus: false,
        isSearchable: true,
        required: false,
        autocomplete: "no",
        className: "full-size-width",
        placeholder: <Trans>Vyberte ...</Trans>,
    };

    constructor(props) {
        super(props);
        this.select = React.createRef();
    }

    componentDidMount() {
        const {focus} = this.props;
        if(focus) {
            setTimeout(() => {
                this.focus();
            }, 0);
        }
    }

    onChange = (option) => {
        const {options, onChange, isMulti, selectAllOption} = this.props;
        if(isMulti) {
            const values = option != null ? 
                (selectAllOption != null && option.find(o => o.value == '_all') != null ?
                    options.map(o => o.value) 
                    : option.map(o => o.value)
                ) 
                : null;
            onChange(values);
        } else {
            const value = option != null ? option.value : null;
            onChange(value);
        }
    };

    focus = () => {
        if(this.select != null && this.select.current != null && this.select.current.focus != null) {
            this.select.current.focus();
        }
    };

    render() {
        const {
            placeholder, maxMenuHeight, options, allowClear, value, disabled, required, tooltiped, className, autoFocus,
            isSearchable, compareFunc, isMulti, currentOption, fixedOptions, height, after, isOptionDisabled, selectAllOption, ...props
        } = this.props;
        let selectedOption = null;
        if(isMulti) 
        {
            if(value == null) 
            {
                selectedOption = [];
            } 
            else if(Array.isArray(value)) 
            {
                selectedOption = value.map(valueItem => options.filter(option => option.value == valueItem)[0])
            } 
            else 
            {
                selectedOption = options.filter(option => option.value == value);
            }
        } 
        else 
        {
            selectedOption = options.filter(option => option.value == value)[0];
        }
        
        selectedOption = selectedOption != null ? selectedOption : null;
        let fullOptions = [...options];

        if(selectAllOption != null)
        {
            // add select-all option
            fullOptions.unshift({value: '_all', label: selectAllOption});
        }
        else if((selectedOption == null || selectedOption.length == 0) && value != null && currentOption != null)
        {
            // current value is no longer available option, add as a first option
            fullOptions.unshift(currentOption);
            selectedOption = currentOption;
        }
        
        let customComponents = {
            Control: createCustomControl(tooltiped),
            Option: CustomOption,
            Input: CustomInput,
        };

        //let customComponents = {};
        if(fullOptions.length*optionHeight > maxMenuHeight*5) 
        {
            customComponents.MenuList = MenuList;
        }

        if(fixedOptions != null)
        {
            customComponents.MultiValueRemove = MultiValueRemove(fixedOptions);
        }

        return <div className={className}>
            <ReactSelect
                {...props}
                ref={this.select}
                autoFocus={autoFocus}
                classNamePrefix={'rSelect'}
                isDisabled={disabled}
                required={required}
                isMulti={isMulti}
                filterOption={isSearchable && compareFunc != null ? createFilterFunc(compareFunc) : undefined}
                isSearchable={isSearchable}
                isClearable={allowClear}
                backspaceRemovesValue={false}
                placeholder={placeholder}
                styles={customStyles(height)}
                theme={themeFunc}
                components={customComponents}
                options={fullOptions}
                maxMenuHeight={maxMenuHeight}
                nullOption={<Trans>Žiadna možnosť</Trans>}
                isOptionDisabled={isOptionDisabled}
                onChange={this.onChange}
                value={selectedOption}
            />
            {after}
        </div>;
    }

}

const createCustomControl = (tooltiped) => (props) => {
    if(tooltiped) {
        const value = (
            props.selectProps != null &&
            props.selectProps.value != null &&
            props.selectProps.value.label != null
        ) ? props.selectProps.value.label : "";
        return <ControlWrapper title={value}>
            <components.Control {...props} />
        </ControlWrapper>;
    } else {
        return <components.Control {...props}/>;
    }
};

const MultiValueRemove = (fixedOptions) => (props) => {
    if (Array.isArray(fixedOptions) && fixedOptions.includes(props.data.value))
      return null;

    return <components.MultiValueRemove {...props} />;
};

const CustomOption = (props) => {
    //maybe add tooltip here as well(there is a problem with rendering)
    return <components.Option {...props}>
        <div className="text-overflow-ellipsis" title={props.data.label}>{props.data.label}</div>
    </components.Option>
};

const CustomInput = (props) => {
    return <components.Input required={props.selectProps.required && !props.hasValue} {...props}>
        {props.children}
    </components.Input>
};

class MenuList extends React.Component {
    render() {
        const {options, children, maxHeight, getValue, selectProps} = this.props;
        if (children.length != null) {
            const [value] = getValue();
            const initialOffset = options.indexOf(value) * optionHeight;
            const childrenHeight = children.length * optionHeight;
            const listHeight = childrenHeight < maxHeight ? childrenHeight : maxHeight;
            const offset = selectProps.inputValue == null || selectProps.inputValue == "" ? initialOffset : 0;
            return (
                <List
                    height={listHeight}
                    itemCount={children.length}
                    itemSize={optionHeight}
                    initialScrollOffset={offset}
                >
                    {({index, style}) => {
                        return <div style={style}>{children[index]}</div>
                    }}
                </List>
            );
        } else {
            return <div className="d-flex align-items-center justify-content-center" style={{height: optionHeight + 'px',}}>
                {selectProps.nullOption}
            </div>;
        }

    }
}

export default Select;