import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';
const TextArea = Input.TextArea;

/**
 * Component that enables input text(area). onChange event is fired only
 * when return is pressed or when focus is lost.
 *
 * @fero
 */

class InputTextArea extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.string,
        changeImmediately: PropTypes.bool,
        autoSize: PropTypes.shape({
            minRows: PropTypes.number.isRequired,
            maxRows: PropTypes.number.isRequired,
        }),
    };

    static defaultProps = {
        autosize: {minRows: 2, maxRows: 5},
        changeImmediately: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            currentValue: props.value,
        };
    }

    componentDidMount() {
        const {autoFocus} = this.props;
        if(autoFocus)
        {
            setTimeout(() => {
                this.focus();
            }, 0)
        }
    }

    componentDidUpdate(prevProps) {
        const {value} = this.props;
        const {value: prevValue} = prevProps;
        if(value != prevValue)
            this.setState({currentValue: value});
    }

    focus() {
        if(this.input != null) 
            this.input.focus();
    }

    onChanged = (ev) => {
        const valueChecked = ev != null && ev.target != null ? ev.target.value : null;
        const {changeImmediately, onChange} = this.props;
        this.setState({currentValue: valueChecked});
        if(changeImmediately && onChange != null)
            onChange(valueChecked);
    };

    onBlur = () => {
        const {currentValue} = this.state;
        const {value, onChange} = this.props;
        if(currentValue != value && onChange != null) 
            onChange(currentValue);
    };

    render() {
        const {value, onChange, autoSize, changeImmediately, ...props} = this.props;
        const {currentValue} = this.state;
        return <TextArea
            {...props}
            autoSize={autoSize}
            onChange={this.onChanged}
            onBlur={this.onBlur}
            value={currentValue}
            ref={node => this.input = node}
        />;
    }

}

export default InputTextArea;