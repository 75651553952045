import * as queryAttributesFunctions from './queryAttributes';
import {GLOBAL_DATA} from '../../constants/globalData';
import {RIGHTS} from '../../constants/Rights';
import {merge} from '../../lib/object';
import * as api from '../../backend/apiCalls';
import { Rights } from '../../constants/propTypesDefinitions';

/*
* Data defaults for data provider.
* It's important to note that withDataHOC renders empty div if dataset is undefined. So if it should be
* rendered at any time set empty object or array as default.
*/

export const DATA_DEFAULTS = {
    [GLOBAL_DATA.PRODUCTS]: undefined,
    [GLOBAL_DATA.PRODUCT_DETAILS]: undefined,
    [GLOBAL_DATA.CONTRACT_PRODUCTS]: undefined,
    [GLOBAL_DATA.DOCUMENTS]: [],
    [GLOBAL_DATA.MANUFACTURERS]: [],
    [GLOBAL_DATA.FLAGS]: [],
    [GLOBAL_DATA.DEPARTMENTS]: [],
    [GLOBAL_DATA.INVENTORY_CLASSES]: [],
    [GLOBAL_DATA.INVOICES]: [],
    [GLOBAL_DATA.INVOICE_CLASSES]: [],
    [GLOBAL_DATA.CATEGORIES]: [],
    [GLOBAL_DATA.PARAMETERS]: [],
    [GLOBAL_DATA.TASKS]: [],
    [GLOBAL_DATA.DASHBOARD_ITEMS]: [],
    [GLOBAL_DATA.DISCOUNT_RULES]: undefined,
    [GLOBAL_DATA.DISCOUNT_TREE]: undefined,
    [GLOBAL_DATA.COUNTRIES]: [],
    [GLOBAL_DATA.CART_CURRENT]: undefined,
    [GLOBAL_DATA.CART_CUSTOMER_DETAILS]: undefined,
    [GLOBAL_DATA.PREDICTION_METHODS]: [],
    [GLOBAL_DATA.CUSTOMER_LIST]: [],
    [GLOBAL_DATA.USER_DETAILS]: undefined,
    [GLOBAL_DATA.USERS] : [],
    [GLOBAL_DATA.USER_DETAILS_ADDRESSES]: [],
    [GLOBAL_DATA.CUSTOMERS]: [],
    [GLOBAL_DATA.CUSTOMER_TYPES]: [],
    [GLOBAL_DATA.EMPLOYEES]: [],
    [GLOBAL_DATA.SUPERVISORS]: [],
    [GLOBAL_DATA.CATEGORY_TREE]: undefined,
    [GLOBAL_DATA.CATEGORY_DETAILS]: undefined,
    [GLOBAL_DATA.CUSTOMER_DETAILS]: undefined,
    [GLOBAL_DATA.CUSTOMER_DETAILS_ADDRESSES]: [],
    [GLOBAL_DATA.CUSTOMER_DETAILS_USERS]: [],
    [GLOBAL_DATA.ACCOUNT_INFO]: undefined,
    [GLOBAL_DATA.ACCOUNT_ADDRESSES]: [],
    [GLOBAL_DATA.COMPANY_ADDRESSES]: [],
    [GLOBAL_DATA.ORDERS]: [],
    [GLOBAL_DATA.ORDER_LINES]: [],
    [GLOBAL_DATA.ORDER_DETAILS]: undefined,
    [GLOBAL_DATA.ORDER_STATES]: [],
    [GLOBAL_DATA.ORDER_LINE_STATES]: [],
    [GLOBAL_DATA.QUOTATIONS]: [],
    [GLOBAL_DATA.QUOTATION_DETAILS]: undefined,
    [GLOBAL_DATA.QUOTATION_STATES]: [],
    [GLOBAL_DATA.QUOTATION_DIVISIBILITY_LEVELS]: undefined,
    [GLOBAL_DATA.REQUESTS]: [],
    [GLOBAL_DATA.REQUEST_DETAILS]: undefined,
    [GLOBAL_DATA.REQUEST_STATES]: [],
    [GLOBAL_DATA.UPLOAD_FILES]: [],
    [GLOBAL_DATA.NEWS]: [],
    [GLOBAL_DATA.CATEGORY_NEWS]: [],
    [GLOBAL_DATA.SHOPPING_LISTS]: [],
    [GLOBAL_DATA.PRODUCT_RELATIVES]: [],
    [GLOBAL_DATA.STOCKS]: [],
    [GLOBAL_DATA.SETTINGS]: [],
    [GLOBAL_DATA.CURRENCIES]: [],
    [GLOBAL_DATA.FORMS]: [],
    [GLOBAL_DATA.FORM_DETAILS]: undefined,
    [GLOBAL_DATA.CHANNELS]: [],
    [GLOBAL_DATA.BACKUP_STATUS]: undefined,
    [GLOBAL_DATA.TRANSFER_TYPES]: [],
    [GLOBAL_DATA.REPORTS]: [],
    [GLOBAL_DATA.LANGUAGES]: [],
    [GLOBAL_DATA.ANALYSIS_TYPES]: [],
    [GLOBAL_DATA.VEHICLES]: [],
    [GLOBAL_DATA.EVENT_TYPES]: [],
    [GLOBAL_DATA.EVENTS]: [],
    [GLOBAL_DATA.ALERTS]: [],
    [GLOBAL_DATA.ALERT_TYPES]: [],
    [GLOBAL_DATA.ERROR]: '',
};

/*
 *  dataDefinitions for dataProvider which structure is:
 *   - dataKey: a key under which it should be loaded to global data(does not have to be initialized)
 *   - fetchFunc: a function that calls api for fetch.
 *   - fetchPropsFunc: function(it will have passed location as attribute) that returns object of properties
 *       for fetch function(will be passed as fetch function first attribute).
 *   - rightsFrom: if defined checks if current rights as higher or equal to defined in this attribute. If not
 *       they are simply not loaded.
 *   - rightsTo: if defined checks if current rights are less or equal to similar to rightsFrom.
 */

export const DATA_DEFS = {
    [GLOBAL_DATA.PRODUCTS]: {
        dataKey: GLOBAL_DATA.PRODUCTS,
        fetchFunc: api.createFetchProductSearch(),
        fetchPropsFunc: queryAttributesFunctions.fetchProductsAttributes,
    },
    [GLOBAL_DATA.PRODUCT_DETAILS]: {
        dataKey: GLOBAL_DATA.PRODUCT_DETAILS,
        fetchFunc: api.createFetchProductDetails(),
        fetchPropsFunc: queryAttributesFunctions.fetchProductDetailsAttributes,
    },
    [GLOBAL_DATA.CONTRACT_PRODUCTS]: {
        dataKey: GLOBAL_DATA.CONTRACT_PRODUCTS,
        fetchFunc: api.createFetchProductSearch(),
        fetchPropsFunc: queryAttributesFunctions.fetchContractProductsAttributes,
    },
    [GLOBAL_DATA.DOCUMENTS]: {
        dataKey: GLOBAL_DATA.DOCUMENTS,
        fetchFunc: api.createFetchAttachmentAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchDocumentsAttributes,
    },
    [GLOBAL_DATA.MANUFACTURERS]: {
        dataKey: GLOBAL_DATA.MANUFACTURERS,
        fetchFunc: api.createFetchAllManufacturers(),
    },
    [GLOBAL_DATA.FLAGS]: {
        dataKey: GLOBAL_DATA.FLAGS,
        fetchFunc: api.createFetchFlags(),
    },
    [GLOBAL_DATA.CUSTOMER_GROUPS]: {
        dataKey: GLOBAL_DATA.CUSTOMER_GROUPS,
        fetchFunc: api.createFetchCustomerGroups(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.DEPARTMENTS]: {
        dataKey: GLOBAL_DATA.DEPARTMENTS,
        fetchFunc: api.createFetchDepartmentAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchDepartmentsAttributes,
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.DASHBOARD_ITEMS]: {
        dataKey: GLOBAL_DATA.DASHBOARD_ITEMS,
        fetchFunc: api.createFetchDashboardItems(),
        fetchPropsFunc: queryAttributesFunctions.fetchDashBoardItemsAttributes,
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.INVENTORY_CLASSES]: {
        dataKey: GLOBAL_DATA.INVENTORY_CLASSES,
        fetchFunc: api.createFetchInventoryClasses(),
    },
    [GLOBAL_DATA.COUNTRIES]: {
        dataKey: GLOBAL_DATA.COUNTRIES,
        fetchFunc: api.createFetchCountryAll(),
    },
    [GLOBAL_DATA.CART_CURRENT]: {
        dataKey: GLOBAL_DATA.CART_CURRENT,
        fetchFunc: api.createFetchCartCurrent(),
        fetchPropsFunc: queryAttributesFunctions.fetchCartCurrentAttributes,
    },
    [GLOBAL_DATA.TASKS]: {
        dataKey: GLOBAL_DATA.TASKS,
        fetchFunc: api.createFetchTaskAll(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.PREDICTION_METHODS]: {
        dataKey: GLOBAL_DATA.PREDICTION_METHODS,
        fetchFunc: api.createFetchPredictionMethods(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.CART_CUSTOMER_DETAILS]: {
        dataKey: GLOBAL_DATA.CART_CUSTOMER_DETAILS,
        fetchFunc: api.createFetchCustomerDetails(),
        fetchPropsFunc: queryAttributesFunctions.fetchCartCustomerDetailsAttributes,
        rightsFrom: RIGHTS.CUSTOMER,
    },
    [GLOBAL_DATA.CATEGORIES]: {
        dataKey: GLOBAL_DATA.CATEGORIES,
        fetchFunc: api.createFetchCategoryAll(),
    },
    [GLOBAL_DATA.PARAMETERS]: {
        dataKey: GLOBAL_DATA.PARAMETERS,
        fetchFunc: api.createFetchParameterAll(),
    },
    [GLOBAL_DATA.CATEGORY_TREE]: {
        dataKey: GLOBAL_DATA.CATEGORY_TREE,
        fetchFunc: api.createFetchCategoryTree(),
    },
    [GLOBAL_DATA.CATEGORY_DETAILS]: {
        dataKey: GLOBAL_DATA.CATEGORY_DETAILS,
        fetchFunc: api.createFetchCategoryDetails(),
        fetchPropsFunc: queryAttributesFunctions.fetchCategoryDetailsAttributes,
    },
    [GLOBAL_DATA.DISCOUNT_RULES]: {
        dataKey: GLOBAL_DATA.DISCOUNT_RULES,
        fetchFunc: api.createFetchDiscountRules(),
        fetchPropsFunc: queryAttributesFunctions.fetchDiscountRulesAttributes,
        rightsFrom: RIGHTS.DEALER,
    },
    [GLOBAL_DATA.DISCOUNT_TREE]: {
        dataKey: GLOBAL_DATA.DISCOUNT_TREE,
        fetchFunc: api.createFetchDiscountTree(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.CUSTOMER_TYPES]: {
        dataKey: GLOBAL_DATA.CUSTOMER_TYPES,
        fetchFunc: api.createFetchCustomerTypes(),
    },
    [GLOBAL_DATA.EMPLOYEES]: {
        dataKey: GLOBAL_DATA.EMPLOYEES,
        fetchFunc: api.createFetchUserEmployees(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.SUPERVISORS]: {
        dataKey: GLOBAL_DATA.SUPERVISORS,
        fetchFunc: api.createFetchUserSupervisors(),
        rightsFrom: RIGHTS.DEALER,
    },
    [GLOBAL_DATA.ACCOUNT_INFO]: {
        dataKey: GLOBAL_DATA.ACCOUNT_INFO,
        fetchFunc: api.createFetchUserCurrent(),
        fetchPropsFunc: queryAttributesFunctions.fetchUserCurrentAttributes,
    },
    [GLOBAL_DATA.ACCOUNT_ADDRESSES]: {
        dataKey: GLOBAL_DATA.ACCOUNT_ADDRESSES,
        fetchFunc: api.createFetchUserAddresses(),
        rightsFrom: RIGHTS.PENDING,
    },
    [GLOBAL_DATA.COMPANY_ADDRESSES]: {
        dataKey: GLOBAL_DATA.COMPANY_ADDRESSES,
        fetchFunc: api.createFetchCompanyAddresses(),
    },
    [GLOBAL_DATA.USERS]: {
        dataKey: GLOBAL_DATA.USERS,
        fetchFunc: api.createFetchUserAll(),
        rightsFrom: RIGHTS.MARKETING,
        fetchPropsFunc: queryAttributesFunctions.fetchUsersAttributes,
    },
    [GLOBAL_DATA.USER_DETAILS]: {
        dataKey: GLOBAL_DATA.USER_DETAILS,
        fetchFunc: api.createFetchUserDetails(),
        fetchPropsFunc: queryAttributesFunctions.fetchUserDetailsAttributes,
    },
    [GLOBAL_DATA.USER_DETAILS_ADDRESSES]: {
        dataKey: GLOBAL_DATA.USER_DETAILS_ADDRESSES,
        fetchFunc: api.createFetchUserAddresses(),
        fetchPropsFunc: queryAttributesFunctions.fetchUserDetailsAddressesAttributes,
    },
    [GLOBAL_DATA.CUSTOMER_DETAILS]: {
        dataKey: GLOBAL_DATA.CUSTOMER_DETAILS,
        fetchFunc: api.createFetchCustomerDetails(),
        fetchPropsFunc: queryAttributesFunctions.fetchCustomerDetailAttributes,
    },
    [GLOBAL_DATA.CUSTOMER_DETAILS_ADDRESSES]: {
        dataKey: GLOBAL_DATA.CUSTOMER_DETAILS_ADDRESSES,
        fetchFunc: api.createFetchCustomerAddresses(),
        fetchPropsFunc: queryAttributesFunctions.fetchCustomerDetailsAddressesAttributes,
    },
    [GLOBAL_DATA.CUSTOMER_DETAILS_USERS]: {
        dataKey: GLOBAL_DATA.CUSTOMER_DETAILS_USERS,
        fetchFunc: api.createFetchUserAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchCustomerDetailsUsersAttributes,
    },
    [GLOBAL_DATA.CUSTOMERS]: {
        dataKey: GLOBAL_DATA.CUSTOMERS,
        fetchFunc: api.createFetchCustomerAll(),
        rightsFrom: RIGHTS.CUSTOMER,
    },
    [GLOBAL_DATA.CUSTOMER_LIST]: {
        dataKey: GLOBAL_DATA.CUSTOMER_LIST,
        fetchFunc: api.createFetchCustomerAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchCustomerListAttributes,
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.ORDERS]: {
        dataKey: GLOBAL_DATA.ORDERS,
        fetchFunc: api.createFetchOrderAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchOrderAllAttributes,
    },
    [GLOBAL_DATA.ORDER_LINES]: {
        dataKey: GLOBAL_DATA.ORDER_LINES,
        fetchFunc: api.createFetchOrderLines(),
        fetchPropsFunc: queryAttributesFunctions.fetchOrderLinesAttributes,
    },
    [GLOBAL_DATA.ORDER_DETAILS]: {
        dataKey: GLOBAL_DATA.ORDER_DETAILS,
        fetchFunc: api.createFetchOrderDetails(),
        fetchPropsFunc: queryAttributesFunctions.fetchOrderDetailsAttributes,
        // rightsFrom: RIGHTS.CUSTOMER,
    },
    [GLOBAL_DATA.ORDER_STATES]: {
        dataKey: GLOBAL_DATA.ORDER_STATES,
        fetchFunc: api.createFetchOrderStates(),
        // rightsFrom: RIGHTS.CUSTOMER,
    },
    [GLOBAL_DATA.ORDER_LINE_STATES]: {
        dataKey: GLOBAL_DATA.ORDER_LINE_STATES,
        fetchFunc: api.createFetchOrderLineStates(),
        // rightsFrom: RIGHTS.CUSTOMER,
    },
    [GLOBAL_DATA.QUOTATIONS]: {
        dataKey: GLOBAL_DATA.QUOTATIONS,
        fetchFunc: api.createFetchQuotationAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchQuotationAllAttributes,
    },
    [GLOBAL_DATA.QUOTATION_DETAILS]: {
        dataKey: GLOBAL_DATA.QUOTATION_DETAILS,
        fetchFunc: api.createFetchQuotationDetails(),
        fetchPropsFunc: queryAttributesFunctions.fetchQuotationDetailsAttributes,
        // rightsFrom: RIGHTS.CUSTOMER,
    },
    [GLOBAL_DATA.QUOTATION_STATES]: {
        dataKey: GLOBAL_DATA.QUOTATION_STATES,
        fetchFunc: api.createFetchQuotationStates(),
    },
    [GLOBAL_DATA.QUOTATION_DIVISIBILITY_LEVELS]: {
        dataKey: GLOBAL_DATA.QUOTATION_DIVISIBILITY_LEVELS,
        fetchFunc: api.createFetchQuotationDivisibility(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.INVOICES]: {
        dataKey: GLOBAL_DATA.INVOICES,
        fetchFunc: api.createFetchInvoiceAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchInvoiceAllAttributes,
    },
    [GLOBAL_DATA.INVOICE_CLASSES]: {
        dataKey: GLOBAL_DATA.INVOICE_CLASSES,
        fetchFunc: api.createFetchInvoiceClasses()
    },
    [GLOBAL_DATA.REQUESTS]: {
        dataKey: GLOBAL_DATA.REQUESTS,
        fetchFunc: api.createFetchRequestAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchRequestAllAttributes,
        // rightsFrom: RIGHTS.CUSTOMER,
    },
    [GLOBAL_DATA.REQUEST_DETAILS]: {
        dataKey: GLOBAL_DATA.REQUEST_DETAILS,
        fetchFunc: api.createFetchRequestDetails(),
        fetchPropsFunc: queryAttributesFunctions.fetchRequestDetailsAttributes,
        // rightsFrom: RIGHTS.CUSTOMER,
    },
    [GLOBAL_DATA.REQUEST_STATES]: {
        dataKey: GLOBAL_DATA.REQUEST_STATES,
        fetchFunc: api.createFetchRequestStates(),
    },
    [GLOBAL_DATA.UPLOAD_FILES]: {
        dataKey: GLOBAL_DATA.UPLOAD_FILES,
        fetchFunc: api.createFetchUploadFiles(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.NEWS]: {
        dataKey: GLOBAL_DATA.NEWS,
        fetchFunc: api.createFetchNewsAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchNewsAllAttributes,
    },
    [GLOBAL_DATA.CATEGORY_NEWS]: {
        dataKey: GLOBAL_DATA.CATEGORY_NEWS,
        fetchFunc: api.createFetchNewsAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchCategoryNewsAttributes,
    },
    [GLOBAL_DATA.SHOPPING_LISTS]: {
        dataKey: GLOBAL_DATA.SHOPPING_LISTS,
        fetchFunc: api.createFetchShoppingListAll(),
    },
    [GLOBAL_DATA.PRODUCT_RELATIVES]: {
        dataKey: GLOBAL_DATA.PRODUCT_RELATIVES,
        fetchFunc: api.createFetchProductRelatives(),
        fetchPropsFunc:  queryAttributesFunctions.fetchProductRelativesAttributes,
    },
    [GLOBAL_DATA.STOCKS]: {
        dataKey: GLOBAL_DATA.STOCKS,
        fetchFunc: api.createFetchStockAll(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.SETTINGS]: {
        dataKey: GLOBAL_DATA.SETTINGS,
        fetchFunc: api.createFetchSettingsAll(),
        rightsFrom: RIGHTS.MANAGER,
    },
    [GLOBAL_DATA.CURRENCIES]: {
        dataKey: GLOBAL_DATA.CURRENCIES,
        fetchFunc: api.createFetchCurrencyAll(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.FORMS]: {
        dataKey: GLOBAL_DATA.FORMS,
        fetchFunc: api.createFetchFormAll(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.FORM_DETAILS]: {
        dataKey: GLOBAL_DATA.FORM_DETAILS,
        fetchFunc: api.createFetchFormDetails(),
        fetchPropsFunc: queryAttributesFunctions.fetchFormDetailsAttributes,
    },
    [GLOBAL_DATA.CHANNELS]: {
        dataKey: GLOBAL_DATA.CHANNELS,
        fetchFunc: api.createFetchChannelAll(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.BACKUP_STATUS]: {
        dataKey: GLOBAL_DATA.BACKUP_STATUS,
        fetchFunc: api.createFetchBackupStatus(),
        rightsFrom: RIGHTS.ADMIN,
    },
    [GLOBAL_DATA.REPORTS]: {
        dataKey: GLOBAL_DATA.REPORTS,
        fetchFunc: api.createFetchReportAll(),
        rightsFrom: RIGHTS.MARKETING,
        fetchPropsFunc: queryAttributesFunctions.fetchReportsAttributes,
    },
    [GLOBAL_DATA.ANALYSIS_TYPES]: {
        dataKey: GLOBAL_DATA.ANALYSIS_TYPES,
        fetchFunc: api.createFetchAnalysisTypes(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.LANGUAGES]: {
        dataKey: GLOBAL_DATA.LANGUAGES,
        fetchFunc: api.createFetchLanguageAll(),
    },
    [GLOBAL_DATA.TRANSFER_TYPES]: {
        dataKey: GLOBAL_DATA.TRANSFER_TYPES,
        fetchFunc: api.createFetchTransferTypes(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.VEHICLES]: {
        dataKey: GLOBAL_DATA.VEHICLES,
        fetchFunc: api.createFetchVehicleAll(),
        rightsFrom: RIGHTS.WORKER,
    },
    [GLOBAL_DATA.EVENT_TYPES]: {
        dataKey: GLOBAL_DATA.EVENT_TYPES,
        fetchFunc: api.createFetchEventTypes(),
        rightsFrom: RIGHTS.WORKER,
    },
    [GLOBAL_DATA.EVENTS]: {
        dataKey: GLOBAL_DATA.EVENTS,
        fetchFunc: api.createFetchEventAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchEventsAttributes,
        rightsFrom: RIGHTS.WORKER,
    },
    [GLOBAL_DATA.ALERT_TYPES]: {
        dataKey: GLOBAL_DATA.ALERT_TYPES,
        fetchFunc: api.createFetchAlertTypes(),
        rightsFrom: RIGHTS.MARKETING,
    },
    [GLOBAL_DATA.ALERTS]: {
        dataKey: GLOBAL_DATA.ALERTS,
        fetchFunc: api.createFetchAlertAll(),
        fetchPropsFunc: queryAttributesFunctions.fetchAlertsAttributes,
        rightsFrom: RIGHTS.MARKETING,
    },
};