import {format, startOfDay, endOfDay, addDays, differenceInCalendarDays} from 'date-fns';

/**
 * @author fero
 */

//api format cannot contain spaces
const apiDateFormat = "yyyy-MM-dd";
const apiDateTimeFormat = "yyyy-MM-dd HH:mm:ss";
const apiTimeFormat = "HH:mm:ss";
const apiMonthFormat = "yyyy-MM";

const presentDateFormat = "dd. MM. yyyy";
const presentDateTimeFormat = "dd. MM. yyyy HH:mm";
const presentTimeFormat = "HH:mm";

const htmlDateFormat = "yyyy-MM-dd";
const htmlDateTimeFormat = "yyyy-MM-dd'T'HH:mm";
const htmlTimeFormat = "HH:mm";

// converts API date to Date object
function parseDateTime(str) {
    if(str == null)
        return null;

    return Date.parse(str.trim().replace(' ', 'T'));
}

// converts API time to milliseconds
function parseDuration(str) {
    if(str == null)
        return null;

    let hours = 0;
    let minutes = 0;
    let seconds = 0.0;

    const parts = str.trim().split(':');
    if(parts.length == 3)
    {
        // HH:mm:ss.fff
        hours = parseInt(parts[0]);
        minutes = parseInt(parts[1]);
        seconds = parseFloat(parts[2]);
    }
    else if(parts.length == 2)
    {
        // hh:mm
        hours = parseInt(parts[0]);
        minutes = parseInt(parts[1]);
    }
    else
    {
        // invalid
        return null;
    }

    if(hours < 0 || minutes < 0 || minutes > 59 || seconds < 0 || seconds >= 60)
        return null;

    return (hours * 3600 + minutes * 60 + seconds) * 1000;
}

// Converts time string to Date object since (1900-01-01 00:00:00)
function parseTime(str) {
    const ms = parseDuration(str);
    if(ms == null)
        return null;

    const t0 = new Date(1900, 0, 1, 0, 0, 0);
    return new Date(t0.valueOf() + ms);
}

// converts string condition to [min, max] range
export function stringToRangeDate(rangeString) {
    if(rangeString != null) {
        const str = rangeString.toUpperCase();
        let matches = str.match(/^BETWEEN (.*) AND (.*)$/);
        if(matches != null)
        {
            return [
                format(parseDateTime(matches[1]), htmlDateFormat), 
                format(parseDateTime(matches[2]), htmlDateFormat)
            ];
        }

        matches = str.match(/^>=(.*)$/);
        if(matches != null)
        {
            return [
                format(parseDateTime(matches[1]), htmlDateFormat), 
                null
            ];
        }
         
        matches = str.match(/^<=(.*)$/);
        if(matches != null)
        {
            return [
                null, 
                format(parseDateTime(matches[1]), htmlDateFormat), 
            ];
        }
    }

    return [null, null];
}

// converts [from, to] to string
export function rangeDateToString(from, to) {
    if((from != null) && (to != null)) 
    {
        const fromString = format(startOfDay(parseDateTime(from)), apiDateTimeFormat);
        const toString = format(endOfDay(parseDateTime(to)), apiDateTimeFormat);
        return "BETWEEN " + fromString + " AND " + toString;
    } 
    else 
    {
        if(from != null) 
        {
            const fromString = format(startOfDay(parseDateTime(from)), apiDateTimeFormat);
            return ">= " + fromString;
        } 
        else if(to != null) 
        {
            const toString = format(endOfDay(parseDateTime(to)), apiDateTimeFormat);
            return "<= " + toString;
        } 
        else 
        {
            return null;
        }
    }
}

export function currentDate() {
    return format(new Date(), apiDateFormat);
}

export function currentMonth() {
    return format(new Date(), apiMonthFormat);
}

export function formatDateTimeAPI(str) {
    if(str != null)
        return format(parseDateTime(str), apiDateTimeFormat);
    else
        return null;
}

export function formatDateAPI(str) {
    if(str != null)
        return format(parseDateTime(str), apiDateFormat);
    else
        return null;
}

export function formatTimeAPI(str) {
    if(str != null)
        return format(parseTime(str), apiTimeFormat);
    else
        return null;
}

export function formatDateTimeHTML(str) {
    if(str != null)
        return format(parseDateTime(str), htmlDateTimeFormat);
    else
        return null;
}

export function formatDateHTML(str) {
    if(str != null)
        return format(parseDateTime(str), htmlDateFormat);
    else
        return null;
}

export function formatTimeHTML(str) {
    if(str != null)
        return format(parseTime(str), htmlTimeFormat);
    else
        return null;
}

// Adds days to given date
export function dateAdd(date, days) {
    if(date != null) 
    {
        if(days != null)
            return format(addDays(parseDateTime(date), days), apiDateFormat);
        else
            return date;
    }
    else 
    {
        return null;
    }
}

// returns difference in days (rounded up) between two dates
export function dateDiff(to, from) {
    if(to != null && from != null)
        return differenceInCalendarDays(parseDateTime(to), parseDateTime(from));
    else
        return null; 
}

// Adds duration to given dateTime
export function dateTimeAdd(date, duration) {
    const t0 = parseDateTime(date);
    const dt = parseDuration(duration);

    if(t0 != null && dt != null)
        return format(t0.valueOf() + dt, apiDateTimeFormat);
    else 
        return null;
}

// returns difference in ms between two dates
export function dateTimeDiff(to, from) {
    const tEnd = parseDateTime(to);
    const tBegin = parseDateTime(from);

    if(tEnd != null && tBegin != null)
        return format(tEnd.valueOf() - tBegin.valueOf(), apiTimeFormat);
    else
        return null;
}

export function formatDate(date) {
    if(date != null)
        return format(parseDateTime(date), presentDateFormat);
    else
        return null;
}

export function formatDateTime(date) {
    if(date != null)
        return format(parseDateTime(date), presentDateTimeFormat);
    else
        return null;
}

export function formatTime(date) {
    if(date != null)
        return format(parseDateTime(date), presentTimeFormat);
    else
        return null;
}