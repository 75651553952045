import React from 'react';
import EshopCookieConsent from './EshopCookieConsent';
import Helmet from '../components/project/Helmet';
import {QUERY_PARAMS} from '../constants/navigation';
import SessionProvider from '../components/sessionProvider/SessionProvider';
import LocationProvider from '../components/locationProvider/LocationProvider';
import DataProvider from '../components/dataProvider/DataProvider';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Loading from '../components/dataProvider/Loading';
import {I18nProvider} from '@lingui/react';
import { i18n } from "@lingui/core";
import {withPrefix} from 'gatsby';
import { getQueryParam } from '../lib/url';

import './bootstrap.less';
import './project.less';
import  './general.less';


const Layout = ({children}) => {
    const favicon = withPrefix("/config/favicon.ico");
    const customStyles = withPrefix("/config/styles.css");
    return <div className="bg-dark full-window-height">
        <div className="bg-white container full-window-height d-flex flex-column">
            <Helmet >
                <link rel="icon" href={favicon}/>
                <link rel="stylesheet" href={customStyles}/>
            </Helmet>
            <div className="flex-item-static">
                <Header/>
            </div>
            <div className="flex-item-dynamic-1">
                <Loading>
                    {children}
                </Loading>
            </div>
            <div className="flex-item-static">
                <Footer/>
            </div>
        </div>
    </div>;
};

// export default Layout;

//export const query = graphql`
//query SiteTitleQuery {
//    site {
//    siteMetadata {
//    title
//}
//}
//}
//`;

class PageWrapper extends React.PureComponent {
    componentDidMount() {
       this.setupLocale();
    }

    componentDidUpdate(prevProps) {
        const locale = this.getLocale(this.props.location);
        const prevLocale = this.getLocale(prevProps.location);
        if(locale != prevLocale)
            this.setupLocale();
    }

    getLocale = (location) => {
        const locale = getQueryParam(location, QUERY_PARAMS.LANG);
        return locale != null ? locale : null;
    };

    setupLocale = () => {
        const {location} = this.props;
        const locale = this.getLocale(location);
        if(locale != null)
        {
            const catalog = require(`../locales/${locale}.js`);
            i18n.load(locale, catalog.messages);
            i18n.activate(locale);
        }
    };

    render() {
        const {location, history, children} = this.props;
        const locale = this.getLocale(location);

        return <SessionProvider 
            locale={locale}
            location={location}
        >
            <LocationProvider
                location={location}
                history={history}
            >
                <DataProvider>
                    <I18nProvider i18n={i18n}>
                        <Layout>
                            {children}
                        </Layout>
                        <EshopCookieConsent/>
                    </I18nProvider>
                </DataProvider>
            </LocationProvider>
        </SessionProvider>;
    }
};

export default PageWrapper;