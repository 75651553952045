import PropTypes from 'prop-types';

/**
 * @fero
 */

//other definitions(must be first due to dependencies)

export const Trade = PropTypes.oneOf(['N', 'P']);
export const ButtonSize = PropTypes.oneOf(['small', 'large']);
export const Location = PropTypes.object;
export const Object = PropTypes.oneOf([
    'backup',
    'category',
    'customer',
    'discount',
    'form',
    'inventory',
    'invoice',
    'news',
    'order',
    'parameter',
    'product',
    'quotation',
    'report',
    'request',
    'shelf',
    'stock',
    'transfer',
    'user',
]);

//definitions for models

export const Decimal = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const ProductFilterParameter = PropTypes.shape({
    id_parameter: PropTypes.number.isRequired,
    val: PropTypes.string.isRequired,
    cnt: PropTypes.number.isRequired,
});

export const ProductFilter = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    unit: PropTypes.string,
    is_numeric: PropTypes.number,
    options: PropTypes.arrayOf(ProductFilterParameter.isRequired).isRequired,
});

export const UserShort = PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullname: PropTypes.string.isRequired,
    e_mail: PropTypes.string,
    phone: PropTypes.string,
});

export const Category = PropTypes.shape({
    id: PropTypes.string.isRequired,
    id_parent: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    description_short: PropTypes.string,
    sequence: PropTypes.number,
});

const CategoryInTreeObject = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id_parent: PropTypes.string,
    image: PropTypes.string,
    description_short: PropTypes.string,
    sequence: PropTypes.number,
    cnt: PropTypes.number,
    children: PropTypes.arrayOf(PropTypes.object),
};

export const CategoryInTree = PropTypes.shape(CategoryInTreeObject);

export const CategoryDetails = PropTypes.shape({
    id: PropTypes.string.isRequired,
    id_parent: PropTypes.string,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    sequence: PropTypes.number,
    description_short: PropTypes.string,
    description_html: PropTypes.string,
    id_salesman: PropTypes.number,
    salesman: UserShort,
});

export const Component = PropTypes.shape({
    id_product: PropTypes.number.isRequried,
    id_component: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    is_service: PropTypes.oneOf([0, 1]).isRequired,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string,
    notice: PropTypes.string
});

export const Components = PropTypes.arrayOf(Component.isRequired);

export const ComponentDetailLine = PropTypes.shape({
    id_product: PropTypes.number.isRequried,
    id_component: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    is_service: PropTypes.oneOf([0, 1]).isRequired,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string,
    notice: PropTypes.string,
    quantity_available: Decimal.isRequired,
    quantity_on_stock: Decimal.isRequired,
    quantity_total: PropTypes.string.isRequired,
});

export const ComponentDetails = PropTypes.shape({
    items: PropTypes.arrayOf(ComponentDetailLine.isRequired).isRequired,
    quantity_available: PropTypes.number.isRequired,
    quantity_on_stock: PropTypes.number.isRequired,
    quantity_ordered: PropTypes.number.isRequired,
});

export const FormComponent = PropTypes.shape({
    id_component: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    is_service: PropTypes.oneOf([0, 1]),
    quantity: Decimal,
    quantity_units: PropTypes.string,
    notice: PropTypes.string
});

export const FormComponents = PropTypes.arrayOf(FormComponent.isRequired);

export const PaymentDiscount = PropTypes.shape({
    id_customer: PropTypes.number.isRequired,
    payment_due: PropTypes.number.isRequired,
    ratio: PropTypes.string.isRequired,
});

export const PaymentDiscounts = PropTypes.arrayOf(PaymentDiscount.isRequired);

export const Department = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    is_active: PropTypes.oneOf([0, 1]),
    e_mail: PropTypes.string,
});

export const Departments = PropTypes.arrayOf(Department.isRequired);

export const Parameter = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    unit: PropTypes.string,
    image: PropTypes.string,
    is_numeric: PropTypes.number,
});

export const Parameters = PropTypes.arrayOf(Parameter.isRequired);

export const ProductParameter = PropTypes.shape({
    id_parameter: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    val: PropTypes.string.isRequired,
    unit: PropTypes.string,
    image: PropTypes.string,
});

export const ProductParameterInEdit = PropTypes.shape({
    id_parameter: PropTypes.number.isRequired,
    val: Decimal,
});

export const Price = PropTypes.shape({
    special_price: PropTypes.string,
    price_min: PropTypes.string,
    price_bulk: PropTypes.string,
    price_pallet: PropTypes.string,
    quantity_min: PropTypes.string,
    quantity_bulk: PropTypes.string,
    quantity_pallet: PropTypes.string,
    active_to: PropTypes.string,
});

export const ProductBarcode = PropTypes.shape({
    code: PropTypes.string,
    notice: PropTypes.string,
});

export const ProductBarcodes = PropTypes.arrayOf(ProductBarcode.isRequired);

export const ProductManufacturer = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    cnt: PropTypes.number,
});

export const ProductRelative = PropTypes.shape({
    id_product: PropTypes.number.isRequired,
    id_relative: PropTypes.number.isRequired,
    thumbnail: PropTypes.string,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    unit_price: Decimal,
    quantity_available: Decimal.isRequired,
    quantity_ordered: Decimal,
    quantity_min: PropTypes.string.isRequired,
    quantity_div: PropTypes.string.isRequired,
    quantity_units: PropTypes.string.isRequired,
    prices: Price.isRequired,
});

export const ProductRelatives = PropTypes.arrayOf(ProductRelative.isRequired);

export const ProductVariant = PropTypes.shape({
    id_product: PropTypes.number.isRequired,
    id_variant: PropTypes.number.isRequired,
    thumbnail: PropTypes.string,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    description: PropTypes.string,
    sequence: PropTypes.number.isRequired,
    unit_price: Decimal,
    quantity_available: Decimal.isRequired,
    quantity_ordered: Decimal,
    quantity_min: PropTypes.string.isRequired,
    quantity_div: PropTypes.string.isRequired,
    quantity_units: PropTypes.string.isRequired,
    prices: Price.isRequired,
});

export const ProductVariants = PropTypes.arrayOf(ProductVariant.isRequired);

export const ProductStockOption = PropTypes.shape({
    id_stock: PropTypes.number,
    stock: PropTypes.string,
    supply_duration: PropTypes.number.isRequired,
    quantity_available: Decimal.isRequired,
    unit_price: Decimal,
});

export const ProductStockOptions = PropTypes.arrayOf(ProductStockOption.isRequired);

export const Product = PropTypes.shape({
    id: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    customer_code: PropTypes.string,
    id_manufacturer: PropTypes.number.isRequired,
    manufacturer: PropTypes.string.isRequired,
    id_discount_group: PropTypes.string,
    discount_group: PropTypes.string,
    id_product_group: PropTypes.string.isRequired,
    description_short: PropTypes.string,
    list_price: PropTypes.string,
    quantity_div: PropTypes.string,
    quantity_min: PropTypes.string,
    quantity_bulk: PropTypes.string,
    quantity_pallet: PropTypes.string,
    quantity_units: PropTypes.string,
    quantity_available: Decimal,
    quantity_ordered: Decimal,
    quantity_on_stock: Decimal,
    stock_options: ProductStockOptions,
    quotations_count: PropTypes.number,
    is_visible: PropTypes.number,
    ext_ref: PropTypes.string,
    prices: Price.isRequired,
    ref_prices: Price,
    price: PropTypes.string,
    thumbnail: PropTypes.string,
    id_form: PropTypes.number,
    components: Components,
    variants: ProductVariants,
    stock_reserve: PropTypes.string,
    supply_duration: PropTypes.number,
    mass: PropTypes.string,
    auto_supply: PropTypes.oneOf([0, 1])
});


export const ProductShort = PropTypes.shape({
    id: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    customer_code: PropTypes.string,
    id_manufacturer: PropTypes.number.isRequired,
    manufacturer: PropTypes.string.isRequired,
    description_short: PropTypes.string,
    quantity_available: Decimal,
    relevance: PropTypes.number,
});

export const ProductsShort = PropTypes.arrayOf(ProductShort.isRequired);

export const ProductDetail = PropTypes.shape({
    id: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    customer_code: PropTypes.string,
    id_manufacturer: PropTypes.number,
    manufacturer: PropTypes.string.isRequired,
    id_discount_group: PropTypes.string,
    discount_group: PropTypes.string,
    id_product_group: PropTypes.string,
    description_short: PropTypes.string,
    list_price: PropTypes.string,
    price_decimals: PropTypes.number,
    quantity_div: PropTypes.string.isRequired,
    quantity_min: PropTypes.string.isRequired,
    quantity_bulk: PropTypes.string,
    quantity_pallet: PropTypes.string,
    quantity_units: PropTypes.string.isRequired,
    quantity_available: Decimal,
    quantity_ordered: Decimal,
    is_visible: PropTypes.number,
    ext_ref: PropTypes.string,
    prices: Price.isRequired,
    ref_prices: Price,
    price: PropTypes.string,
    thumbnail: PropTypes.string,
    id_form: PropTypes.number,
    components: Components,
    stock_reserve: PropTypes.string,
    supply_duration: PropTypes.number,
    mass: PropTypes.string,
    auto_supply: PropTypes.oneOf([0, 1]),
    params: PropTypes.arrayOf(ProductParameter.isRequired),
    description_html: PropTypes.string,
    tax_rate: Decimal,
    id_tax_rate: PropTypes.number,
    barcodes: PropTypes.arrayOf(ProductBarcode.isRequired),
    relatives: ProductRelatives,
    variants: ProductVariants,
    path: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
});

export const Products = PropTypes.shape({
    total_count: PropTypes.number,
    page_size: PropTypes.number,
    page_number: PropTypes.string,
    tree: CategoryInTree,
    categories: PropTypes.arrayOf(Category.isRequired),
    filters: PropTypes.arrayOf(ProductFilter.isRequired),
    lines: PropTypes.arrayOf(Product.isRequired),
    manufacturers: PropTypes.arrayOf(ProductManufacturer.isRequired),
});

export const TradeProduct = PropTypes.shape({
    id: PropTypes.number.isRequired,
    thumbnail: PropTypes.string,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    unit_price: Decimal,
    quantity_available: Decimal.isRequired,
    quantity_ordered: Decimal,
    quantity_div: PropTypes.string.isRequired,
    quantity_units: PropTypes.string.isRequired,
    prices: Price.isRequired,
    variants: ProductVariants,
});

export const PackageInfo = PropTypes.shape({
    quantity_units: PropTypes.string.isRequired,
    quantity_bulk: PropTypes.string,
    quantity_pallet: PropTypes.string,
});

export const ProductTransfer = PropTypes.shape({
    id_product: PropTypes.number.isRequired,
    id_transfer_item: PropTypes.number.isRequired,
    id_transfer: PropTypes.number.isRequired,
    dealer_reference: PropTypes.string.isRequired,
    customer_reference: PropTypes.string,
    id_order: PropTypes.number,
    id_invoice: PropTypes.number,
    id_stock: PropTypes.number.isRequired,
    stock: PropTypes.string.isRequired,
    sent_at: PropTypes.string,
    id_customer: PropTypes.number,
    customer: PropTypes.string,
    trade: PropTypes.string.isRequired,
    id_type: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id_status: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string.isRequired,
    unit_value: PropTypes.string.isRequired,
    unit_price: Decimal.isRequired,
    line_value: PropTypes.string.isRequired,
    line_price: Decimal.isRequired,
});

export const ProductTransfers = PropTypes.arrayOf(ProductTransfer);

export const ProductOrder = PropTypes.shape({
    id_product: PropTypes.number.isRequired,
    id_order: PropTypes.number.isRequired,
    trade: Trade,
    sent_at: PropTypes.string,
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    dealer_reference: PropTypes.string.isRequired,
    customer_reference: PropTypes.string,
    unit_price: Decimal.isRequired,
    quantity_units: PropTypes.string.isRequired,
    quantity: Decimal.isRequired,
    quantity_opened: Decimal.isRequired,
    requested_at: PropTypes.string,
    available_at: PropTypes.string,
});

export const ProductOrders = PropTypes.arrayOf(ProductOrder);

export const ProductQuotation = PropTypes.shape({
    id_product: PropTypes.number.isRequired,
    id_quotation: PropTypes.number.isRequired,
    trade: Trade,
    sent_at: PropTypes.string,
    active_to: PropTypes.string,
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    dealer_reference: PropTypes.string.isRequired,
    customer_reference: PropTypes.string,
    unit_price: Decimal.isRequired,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string.isRequired,
});

export const ProductQuotations = PropTypes.arrayOf(ProductQuotation);

export const ProductRequest = PropTypes.shape({
    id_product: PropTypes.number.isRequired,
    id_request: PropTypes.number.isRequired,
    trade: Trade,
    sent_at: PropTypes.string,
    customer: PropTypes.string.isRequired,
    dealer_reference: PropTypes.string.isRequired,
    customer_reference: PropTypes.string,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string.isRequired,
});

export const ProductRequests = PropTypes.arrayOf(ProductRequest);

export const ProductAvailStockReservation = PropTypes.shape({
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    id_transfer: PropTypes.number,
    id_dealer: PropTypes.number,
    dealer: PropTypes.string,
    reserved_at: PropTypes.string,
    transfer_reference: PropTypes.string,
    transfer_date: PropTypes.string,
    quantity: PropTypes.number,
    id_stock_item: PropTypes.arrayOf(PropTypes.number).isRequired,
});

export const ProductAvailStockItem = PropTypes.shape({
    id_stock: PropTypes.number.isRequired,
    stock: PropTypes.string.isRequired,
    id_shelf: PropTypes.number,
    shelf: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    quantity_units: PropTypes.string.isRequired,
    unit_price: Decimal.isRequired,
    reservations: PropTypes.arrayOf(ProductAvailStockReservation).isRequired,
});

export const ProductAvailStockItems = PropTypes.arrayOf(ProductAvailStockItem);

export const ProductSupplierStockItem = PropTypes.shape({
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    stock: PropTypes.string,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string.isRequired,
    available_at: PropTypes.string,
});

export const ProductSupplierStockItems = PropTypes.arrayOf(ProductSupplierStockItem);

export const OrderLineReservation = PropTypes.shape({
    id_order_item: PropTypes.number.isRequired,
    id_product: PropTypes.number.isRequired,
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    id_dealer: PropTypes.number,
    dealer: PropTypes.string,
    quantity: Decimal.isRequired,
    quantity_to_process: PropTypes.string.isRequired,
});

export const ProductAvailOrderedItem = PropTypes.shape({
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    id_item: PropTypes.number,
    id_order: PropTypes.number,
    dealer_reference: PropTypes.string.isRequired,
    customer_reference: PropTypes.string,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string.isRequired,
    unit_price: Decimal.isRequired,
    requested_at: PropTypes.string,
    available_at: PropTypes.string,
    reservations: PropTypes.arrayOf(OrderLineReservation.isRequired),
});

export const ProductAvailOrderedItems = PropTypes.arrayOf(ProductAvailOrderedItem);

export const ProductPurchasePrice = PropTypes.shape({
    id_product: PropTypes.number.isRequired,
    id_supplier: PropTypes.number.isRequired,
    supplier: PropTypes.string.isRequired,
    unit_price: Decimal.isRequired,
    quantity_units: PropTypes.string.isRequired,
});

export const ProductCrossReference = PropTypes.shape({
    id: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    unit_price: Decimal,
    quantity_available: Decimal.isRequired,
    quantity_ordered: Decimal,
    quantity_units: PropTypes.string.isRequired,
    quantity_div: PropTypes.string.isRequired,
    prices: Price.isRequired,
    replaces: PropTypes.arrayOf(PropTypes.shape({
        id_product: PropTypes.number,
        designation: PropTypes.string.isRequired,
        id_manufacturer: PropTypes.number,
        manufacturer: PropTypes.string,
        deviations: PropTypes.arrayOf(PropTypes.shape({
            parameter: PropTypes.string.isRequired,
            original_val: Decimal,
            replacement_val: Decimal,
        }))
    }).isRequired)
});

export const ProductReplacement = PropTypes.shape({
    id_replacement: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    unit_price: Decimal,
    quantity_available: Decimal.isRequired,
    quantity_ordered: Decimal,
    quantity_units: PropTypes.string.isRequired,
});

export const ProductProduct = PropTypes.shape({
    id_product: PropTypes.number.isRequired,
    id_component: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string.isRequired,
    orders: PropTypes.array.isRequired,
});

export const PriceExplainRow = PropTypes.shape({
    customer: PropTypes.string,
    price: PropTypes.string,
    discount_group: PropTypes.string,
    ref_customer: PropTypes.string,
    ref_ratio: PropTypes.string,
    active_from: PropTypes.string,
    active_to: PropTypes.string,
    notice: PropTypes.string,
    dealer: PropTypes.string,
});

export const Manufacturer = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

export const Manufacturers = PropTypes.arrayOf(Manufacturer.isRequired);

export const CustomerGroup = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

export const CustomerGroups = PropTypes.arrayOf(CustomerGroup.isRequired);

export const InventoryClass = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    sequence: PropTypes.number.isRequired,
    sales_count_min: PropTypes.number,
    sales_count_max: PropTypes.number,
    customers_count_min: PropTypes.number,
    customers_count_max: PropTypes.number,
    sales_age_min: PropTypes.number,
    sales_age_max: PropTypes.number,
    stock_age_min: PropTypes.number,
    stock_age_max: PropTypes.number,
});

export const InventoryClasses = PropTypes.arrayOf(InventoryClass.isRequired);

export const UserAddress = PropTypes.shape({
    id: PropTypes.number.isRequired,
    id_user: PropTypes.number,
    id_country: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    organization: PropTypes.string,
    ext_ref: PropTypes.string,
    is_visible: PropTypes.number.isRequired,
});

export const Customer = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    is_visible: PropTypes.number.isRequired,
    id_type: PropTypes.oneOf(['P', 'F', 'C']).isRequired,
});

export const CustomerStock = PropTypes.shape({
    id_customer: PropTypes.number,
    id_stock: PropTypes.number.isRequired,
    priority: PropTypes.number,
    min_profit_ratio: Decimal,
});

export const CustomerDetails = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    id_type: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    ext_ref: PropTypes.string,
    ico: PropTypes.string,
    dic: PropTypes.string,
    ic_dph: PropTypes.string,
    is_visible: PropTypes.number.isRequired,
    id_channel: PropTypes.number,
    channel: PropTypes.string,
    address: UserAddress,
    stocks: PropTypes.arrayOf(CustomerStock.isRequired),
    id_manufacturer: PropTypes.arrayOf(PropTypes.number.isRequired),
    invoicing_period: PropTypes.number,
    invoicing_limit: PropTypes.string,
    invoicing_email: PropTypes.string,
    auto_invoice: PropTypes.oneOf([0, 1]),
    payment_due: PropTypes.number,
    id_transport_type: PropTypes.number,
    transport_type: PropTypes.string,
    id_payment_method: PropTypes.number,
    payment_method: PropTypes.string,
    free_payment: PropTypes.oneOf([0, 1]),
    payment_discounts: PaymentDiscounts,
});

export const CustomersArray = PropTypes.arrayOf(Customer.isRequired);

export const CustomerDefaults = PropTypes.shape({
    id_default_customer: PropTypes.number.isRequired,
    id_default_ref_customer: PropTypes.number.isRequired,
});

export const DiscountGroup = PropTypes.shape({
    id: PropTypes.string.isRequired,
    id_parent: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
});

const DiscountGroupInTreeObject = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id_parent: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.object),
};

export const DiscountGroupInTree = PropTypes.shape(DiscountGroupInTreeObject);

export const DiscountRule = PropTypes.shape({
    id_customer: PropTypes.number.isRequired,
    id_discount_group: PropTypes.string.isRequired,
    id_ref_customer: PropTypes.number.isRequired,
    ref_customer: PropTypes.string.isRequired,
    ref_ratio: PropTypes.string.isRequired,
    bulk_ratio: PropTypes.string.isRequired,
    pallet_ratio: PropTypes.string.isRequired,
    active_from: PropTypes.string.isRequired,
    id_dealer: PropTypes.number.isRequired,
    dealer: PropTypes.string.isRequired,
    is_inherited: PropTypes.bool.isRequired,
});

export const DiscountRulesTree = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id_parent: PropTypes.string,
    description: PropTypes.string,
    rule: DiscountRule,
    children: PropTypes.arrayOf(PropTypes.object),
});

export const CartLine = PropTypes.shape({
    id_item: PropTypes.number.isRequired,
    id_product: PropTypes.number.isRequired,
    id_customer: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    customer_code: PropTypes.string,
    thumbnail: PropTypes.string,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string.isRequired,
    quantity_available: Decimal,
    quantity_ordered: Decimal,
    is_available: PropTypes.number,
    quotations_count: PropTypes.number,
    unit_price: Decimal,
    is_special: PropTypes.number,
    line_price: Decimal,
    tax_rate: Decimal.isRequired,
    total_price: Decimal,
    customer_notice: PropTypes.string,
    dealer_notice: PropTypes.string,
    requested_at: PropTypes.string,
    sequence: PropTypes.number.isRequired
});

export const CartCurrent = PropTypes.shape({
    lines: PropTypes.arrayOf(CartLine.isRequired).isRequired,
    lines_count: PropTypes.number.isRequired,
    sum_netto: PropTypes.string,
    sum_brutto: PropTypes.string,
});

export const PredictionMethod = PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
});

export const UserIndex = PropTypes.shape({
    id_user: PropTypes.number.isRequired,
    id_customer: PropTypes.number.isRequired,
    fullname: PropTypes.string.isRequired,
});

export const UserInfo = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    title: PropTypes.string,
    e_mail: PropTypes.string,
    phone: PropTypes.string,
    role: PropTypes.string.isRequired,
    invoice_address: UserAddress,
    delivery_address: UserAddress,
    id_language: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    organization: PropTypes.string,
    ico: PropTypes.string,
    dic: PropTypes.string,
    ic_dph: PropTypes.string,
    is_assigned: PropTypes.oneOf([0, 1]),
    id_customer: PropTypes.number.isRequired,
});

export const User = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    title: PropTypes.string,
    e_mail: PropTypes.string,
    phone: PropTypes.string,
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    access_level: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
    id_invoice_address: PropTypes.number,
    id_delivery_address: PropTypes.number,
});

export const UserDetail = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    title: PropTypes.string,
    e_mail: PropTypes.string,
    phone: PropTypes.string,
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    access_level: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
    invoice_address: UserAddress.isRequired,
    delivery_address: UserAddress,
    registered_at: PropTypes.string.isRequired,
    last_access_at: PropTypes.string,
    access_count: PropTypes.number.isRequired,
    id_language: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    organization: PropTypes.string,
    ico: PropTypes.string,
    dic: PropTypes.string,
    ic_dph: PropTypes.string,
    is_assigned: PropTypes.oneOf([0, 1]),
});

export const ChannelValue = PropTypes.shape({
    val: PropTypes.string.isRequired,
    label: PropTypes.string
});

export const ChannelOption = PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.oneOf(['N', 'D', 'B', 'T', 'E', 'M']).isRequired,
    is_required: PropTypes.bool,
    default_value: PropTypes.any,//TODO specify type, numbers objects and so on
    values: PropTypes.arrayOf(ChannelValue),
});

export const ChannelOptionsType = PropTypes.arrayOf(ChannelOption.isRequired);

export const ChannelArg = PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    val: PropTypes.any,
    //val: PropTypes.oneOf([ChannelValue.isRequired, PropTypes.arrayOf(ChannelValue.isRequired).isRequired]),
});

export const ChannelArgs = PropTypes.arrayOf(ChannelArg.isRequired);

export const PaymentMethod = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
});

export const TransportType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.string,
});

export const Order = PropTypes.shape({
    id: PropTypes.number.isRequired,
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    id_user: PropTypes.number,
    user: PropTypes.string,
    id_dealer: PropTypes.number,
    dealer: PropTypes.string,
    id_creator: PropTypes.number.isRequired,
    creator: PropTypes.string.isRequired,
    id_salesman: PropTypes.number,
    salesman: PropTypes.string,
    id_salesman2: PropTypes.number,
    salesman2: PropTypes.string,
    is_external: PropTypes.number,
    dealer_reference: PropTypes.string,
    customer_reference: PropTypes.string,
    date_requested: PropTypes.string,
    is_divisible: PropTypes.oneOf([0, 1]).isRequired,
    is_critical: PropTypes.oneOf([0, 1]).isRequired,
    last_change: PropTypes.string.isRequired,
    sent_at: PropTypes.string,
    id_quotation: PropTypes.number,
    quotation_reference: PropTypes.string,
    quotation_customer_ref: PropTypes.string,
    customer_notices: PropTypes.string,
    dealer_notices: PropTypes.string,
    sum_netto: PropTypes.string,
    sum_brutto: PropTypes.string,
});

export const AvailabilityHistoryItem = PropTypes.shape({
    id_order_item: PropTypes.number.isRequired,
    changed_at: PropTypes.string.isRequired,
    available_at: PropTypes.string.isRequired,
    id_who: PropTypes.number.isRequired,
    who: PropTypes.string.isRequired,
});

export const OrderDetailLine = PropTypes.shape({
    id_item: PropTypes.number.isRequired,
    id_order: PropTypes.number.isRequired,
    id_product: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    customer_code: PropTypes.string,
    product_ext_ref: PropTypes.string,
    unit_price: Decimal.isRequired,
    quantity: Decimal.isRequired,
    quantity_to_process: PropTypes.string.isRequired,
    package_quantity: Decimal.isRequired,
    package_quantity_to_process: PropTypes.string.isRequired,
    id_package_type: PropTypes.number,
    quantity_per_package: PropTypes.string,
    quantity_opened: Decimal,
    quantity_on_stock: Decimal,
    quantity_reserved: PropTypes.string,
    quantity_ordered: Decimal,
    quantity_units: PropTypes.string.isRequired,
    line_price: Decimal.isRequired,
    tax_rate: Decimal.isRequired,
    total_price: Decimal.isRequired,
    customer_notice: PropTypes.string,
    dealer_notice: PropTypes.string,
    status: PropTypes.string.isRequired,
    requested_at: PropTypes.string,
    available_at: PropTypes.string,
    quotations_count: PropTypes.number,
    sequence: PropTypes.number.isRequired,
    is_atomic: PropTypes.oneOf([0, 1]),
    reservations: PropTypes.arrayOf(OrderLineReservation.isRequired),
});

export const OrderState = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

export const OrderLineState = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

export const OrderDetail = PropTypes.shape({
    id: PropTypes.number.isRequired,
    trade: Trade.isRequired,
    id_status: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    dealer_reference: PropTypes.string,
    customer_reference: PropTypes.string,
    customer_reference_duplicity: PropTypes.number.isRequired,
    customer: CustomerDetails.isRequired,
    user: UserShort,
    dealer: UserShort,
    invoice_address: UserAddress.isRequired,
    delivery_address: UserAddress.isRequired,
    transport_type: TransportType.isRequired,
    payment_method: PaymentMethod.isRequired,
    customer_notices: PropTypes.string,
    dealer_notices: PropTypes.string,
    is_divisible: PropTypes.oneOf([0, 1]).isRequired,
    is_critical: PropTypes.oneOf([0, 1]).isRequired,
    id_dst_stock: PropTypes.number,
    dst_stock: PropTypes.string,
    last_change: PropTypes.string.isRequired,
    sent_at: PropTypes.string,
    id_quotation: PropTypes.number,
    quotation_reference: PropTypes.string,
    quotation_customer_ref: PropTypes.string,
    lines: PropTypes.arrayOf(OrderDetailLine.isRequired).isRequired,
    sum_netto: PropTypes.string,
    sum_brutto: PropTypes.string,
    prepayment: PropTypes.string,
    payment_result: PropTypes.string,
    options: ChannelOptionsType,
    args: ChannelArgs,
});

export const Invoice = PropTypes.shape({
    id: PropTypes.number.isRequired,
    trade: Trade.isRequired,
    id_type: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id_deal: PropTypes.number.isRequired,
    deal_code: PropTypes.string.isRequired,
    id_department: PropTypes.number,
    class: PropTypes.string.isRequired,
    dealer_reference: PropTypes.string.isRequired,
    customer_reference: PropTypes.string,
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    id_user: PropTypes.number.isRequired,
    user: PropTypes.string.isRequired,
    id_salesman: PropTypes.number,
    salesman: PropTypes.string,
    sent_at: PropTypes.string,
    transfered_at: PropTypes.string,
    invoiced_at: PropTypes.string.isRequired,
    payment_due: PropTypes.string.isRequired,
    overdue: PropTypes.number,
    is_overdue: PropTypes.oneOf([0, 1]),
    saldo: Decimal.isRequired,
    sum_netto: Decimal.isRequired,
    sum_brutto: Decimal.isRequired,
    prepayment: Decimal.isRequired,
    payment: Decimal.isRequired,
    id_currency: PropTypes.string,
    exchange_rate: Decimal.isRequired,
    id_payment_method: PropTypes.number,
    payment_method: PropTypes.string,
    incoterms: PropTypes.string,
    incoterms_description: PropTypes.string,
    paid_at: PropTypes.string,
    variable_symbol: PropTypes.string,
    constant_symbol: PropTypes.string,
    customer_notices: PropTypes.string,
    dealer_notices: PropTypes.string,
    id_origin_invoice: PropTypes.number,
    origin_invoice_reference: PropTypes.string,
});

export const InvoiceDetailLine = PropTypes.shape({
    id_item: PropTypes.number.isRequired,
    id_invoice: PropTypes.number.isRequired,
    id_product: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    customer_code: PropTypes.string,
    quantity: Decimal.isRequired,
    unit_price: Decimal.isRequired,
    line_price: Decimal.isRequired,
    intrastat: PropTypes.string,
    id_currency: PropTypes.string,
});

export const Request = PropTypes.shape({
    id: PropTypes.number.isRequired,
    dealer_reference: PropTypes.string.isRequired,
    customer_reference: PropTypes.string,
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    id_user: PropTypes.number.isRequired,
    user: PropTypes.string.isRequired,
    id_dealer: PropTypes.number,
    dealer: PropTypes.string,
    id_draft: PropTypes.number,
    id_quotation: PropTypes.number,
    quotation_reference: PropTypes.string,
    id_ref_request: PropTypes.number,
    ref_request_reference: PropTypes.string,
    sent_at: PropTypes.string,
    customer_notices: PropTypes.string,
    dealer_notices: PropTypes.string,
});

export const RequestDetailLine = PropTypes.shape({
    id_item: PropTypes.number.isRequired,
    id_request: PropTypes.number.isRequired,
    id_product: PropTypes.number,
    designation: PropTypes.string,
    manufacturer: PropTypes.string,
    customer_code: PropTypes.string,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string,
    customer_notice: PropTypes.string,
    dealer_notice: PropTypes.string,
    requested_at: PropTypes.string,
    quotations_count: PropTypes.number,
    sequence: PropTypes.number.isRequired,
});

export const RequestState = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

export const RequestDetail = PropTypes.shape({
    id: PropTypes.number.isRequired,
    trade: Trade.isRequired,
    id_status: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    dealer_reference: PropTypes.string.isRequired,
    customer_reference: PropTypes.string,
    customer: CustomerDetails.isRequired,
    user: UserShort.isRequired,
    dealer: UserShort,
    id_draft: PropTypes.number,
    id_quotation: PropTypes.number,
    quotation_reference: PropTypes.string,
    id_ref_request: PropTypes.number,
    ref_request_reference: PropTypes.string,
    sent_at: PropTypes.string,
    customer_notices: PropTypes.string,
    dealer_notices: PropTypes.string,
    lines: PropTypes.arrayOf(RequestDetailLine.isRequired).isRequired,
    options: ChannelOptionsType,
    args: ChannelArgs,
});

export const Quotation = PropTypes.shape({
    id: PropTypes.number.isRequired,
    dealer_reference: PropTypes.string.isRequired,
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    id_user: PropTypes.number.isRequired,
    user: PropTypes.string.isRequired,
    id_dealer: PropTypes.number.isRequired,
    dealer: PropTypes.string.isRequired,
    id_request: PropTypes.number,
    request_reference: PropTypes.string,
    id_ref_quotation: PropTypes.number,
    ref_quotation_reference: PropTypes.string,
    last_change: PropTypes.string,
    sent_at: PropTypes.string,
    active_to: PropTypes.string,
    is_divisible: PropTypes.number,
    customer_notices: PropTypes.string,
    dealer_notices: PropTypes.string,
});

export const QuotationDetailLine = PropTypes.shape({
    id_item: PropTypes.number.isRequired,
    id_quotation: PropTypes.number.isRequired,
    id_product: PropTypes.number,
    designation: PropTypes.string,
    manufacturer: PropTypes.string,
    customer_code: PropTypes.string,
    requested_price: PropTypes.string,
    unit_price: Decimal,
    quantity: Decimal.isRequired,
    package_quantity: Decimal.isRequired,
    ordered_quantity: Decimal,
    quantity_units: PropTypes.string.isRequired,
    line_price: Decimal,
    tax_rate: Decimal,
    total_price: Decimal,
    customer_notice: PropTypes.string,
    dealer_notice: PropTypes.string,
    requested_at: PropTypes.string,
    supply_duration: PropTypes.number,
    available_at: PropTypes.number,
    quotations_count: PropTypes.number,
    sequence: PropTypes.number.isRequired,
});

export const QuotationState = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

export const QuotationDetail = PropTypes.shape({
    id: PropTypes.number.isRequired,
    trade: Trade.isRequired,
    id_status: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    dealer_reference: PropTypes.string.isRequired,
    customer: CustomerDetails.isRequired,
    user: UserShort.isRequired,
    dealer: UserShort.isRequired,
    id_request: PropTypes.number,
    request_reference: PropTypes.string,
    id_ref_quotation: PropTypes.number,
    ref_quotation_reference: PropTypes.string,
    sent_at: PropTypes.string,
    last_change: PropTypes.string,
    active_to: PropTypes.string,
    customer_notices: PropTypes.string,
    dealer_notices: PropTypes.string,
    expiration_notices: PropTypes.string,
    lines: PropTypes.arrayOf(QuotationDetailLine.isRequired).isRequired,
    sum_netto: PropTypes.string,
    sum_brutto: PropTypes.string,
    divisibility_level: PropTypes.number.isRequired,
    divisibility_name: PropTypes.string.isRequired,
    options: ChannelOptionsType,
    args: ChannelArgs,
});

export const Employee = PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullname: PropTypes.string.isRequired,
});

export const Rights = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
});

export const ColDef = PropTypes.shape({
    headerCell: PropTypes.node,
    class: PropTypes.string,
    rightsFrom: PropTypes.number,
    rightsTo: PropTypes.number,
});

export const SimpleColDef = PropTypes.shape({
    render: PropTypes.func,
    dataKey: PropTypes.string,
    class: PropTypes.string,
    rightsFrom: PropTypes.number,
    rightsTo: PropTypes.number,
});

export const UploadedFile = PropTypes.shape({
    filename: PropTypes.string.isRequired,
    ext: PropTypes.string.isRequired,
});

export const NewsArticle = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    id_author: PropTypes.number.isRequired,
    author: PropTypes.string.isRequired,
    active_from: PropTypes.string.isRequired,
    active_to: PropTypes.string,
    last_change: PropTypes.string,
    thumbnail: PropTypes.string,
    description_short: PropTypes.string,
    html: PropTypes.string,
});

export const News = PropTypes.arrayOf(NewsArticle.isRequired);

export const SaleHistoryItem = PropTypes.shape({
    id_stock: PropTypes.number,
    stock: PropTypes.string.isRequired,
    date: PropTypes.string,
    id_customer: PropTypes.number,
    customer: PropTypes.string.isRequired,
    trade: PropTypes.string.isRequired,
    id_transfer_type: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    quantity_units: PropTypes.string.isRequired,
    unit_value: PropTypes.number.isRequired,
    unit_price: PropTypes.number.isRequired,
});

export const SaleHistory = PropTypes.shape({
    lines: PropTypes.arrayOf(SaleHistoryItem.isRequired),
    received_quantity: PropTypes.number,
    received_value: PropTypes.number,
    dispatched_quantity: PropTypes.number,
    dispatched_value: PropTypes.number,
    dispatched_price: PropTypes.number,
});

export const StockDetailItem = PropTypes.shape({
    id_stock: PropTypes.number,
    stock: PropTypes.string.isRequired,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string.isRequired,
    unit_value: Decimal,
});

export const ShoppingListDesc = PropTypes.shape({
    id: PropTypes.number.isRequired,
    id_user: PropTypes.number.isRequired,
    user: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    token: PropTypes.string,
});

export const ShoppingListDescArray = PropTypes.arrayOf(ShoppingListDesc.isRequired);

export const ShoppingListItem = PropTypes.shape({
    id_item: PropTypes.number.isRequired,
    id_list: PropTypes.number.isRequired,
    id_product: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    quantity: Decimal.isRequired,
    quantity_units: PropTypes.string.isRequired,
    unit_price: Decimal.isRequired,
    line_price: Decimal.isRequired,
    tax_rate: Decimal.isRequired,
    total_price: Decimal.isRequired,
    notice: PropTypes.string,
    sequence: PropTypes.number.isRequired,
});

export const ShoppingListItems = PropTypes.arrayOf(ShoppingListItem.isRequired);

export const ShoppingListDetails = PropTypes.shape({
    id: PropTypes.number.isRequired,
    id_user: PropTypes.number.isRequired,
    user: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    token: PropTypes.string,
    is_mine: PropTypes.number.isRequired,
    lines: ShoppingListItems.isRequired,
});

export const ReportOperation = PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    arity: PropTypes.number,
    min_arity: PropTypes.number,
    is_aggregate: PropTypes.bool,
});

export const ReportFormula = PropTypes.oneOfType([
    PropTypes.shape({
        val: PropTypes.string,
    }),
    PropTypes.shape({
        col: PropTypes.string,
    }),
    PropTypes.shape({
        operation: ReportOperation,
        inputs: PropTypes.array.isRequired,
    }).isRequired,
]);

export const ReportTable = PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
});

export const ReportColumn = PropTypes.shape({
    label: PropTypes.string,
    formula: ReportFormula,
});

export const ReportFilter = ReportFormula;

export const ReportDefinition = PropTypes.shape({
    name: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
    filter: ReportFilter,
    key_columns: PropTypes.arrayOf(PropTypes.string),
    compare_column: PropTypes.string,
    order_columns: PropTypes.arrayOf(PropTypes.string),
    args: PropTypes.object,
    show_summary: PropTypes.oneOf([1, 0]),
    id_owner: PropTypes.number,
    owner: PropTypes.string,
});

export const ReportDefinitions = PropTypes.arrayOf(ReportDefinition.isRequired);

export const Report = PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    args: PropTypes.object,
});

export const Attachment = PropTypes.shape({
    id: PropTypes.number.isRequired,
    id_order: PropTypes.number,
    id_quotation: PropTypes.number,
    id_request: PropTypes.number,
    id_user: PropTypes.number,
    title: PropTypes.string,
    body: PropTypes.string,
    file: PropTypes.string,
    is_visible: PropTypes.oneOf([0, 1]),
});

export const Attachments = PropTypes.arrayOf(Attachment.isRequired);

export const FormOptionValue = PropTypes.shape({
    id_option: PropTypes.number.isRequired,
    val: PropTypes.string.isRequired,
    label: PropTypes.string,
    thumbnail: PropTypes.string,
    id_component: PropTypes.number,
    quantity: Decimal,
});

export const Form = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

export const Forms = PropTypes.arrayOf(Form.isRequired);

export const FormOptionValues = PropTypes.arrayOf(FormOptionValue.isRequired);

export const FormOption = PropTypes.shape({
    id: PropTypes.number.isRequired,
    id_form: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    id_type: PropTypes.oneOf(['N', 'E', 'B']).isRequired,
    is_required: PropTypes.oneOf([0, 1]),
    id_parameter: PropTypes.number,
    min_val: PropTypes.string,
    max_val: PropTypes.string,
    val_step: PropTypes.string,
    id_component: PropTypes.number,
    quantity: Decimal,
    values: FormOptionValues.isRequired,
    unit: PropTypes.string,
});

export const FormOptions = PropTypes.arrayOf(FormOption.isRequired);

export const FormDetails = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    validator: PropTypes.string,
    designator: PropTypes.string,
    consumer: PropTypes.string,
    options: FormOptions.isRequired,
});

export const DealDocument = PropTypes.shape({
    id_deal: PropTypes.number.isRequired,
    id_obj: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    trade: Trade.isRequired,
    dealer_reference: PropTypes.string.isRequired,
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    id_user: PropTypes.number.isRequired,
    user: PropTypes.string.isRequired,
    sent_at: PropTypes.string,
    id_status: PropTypes.string,
    status: PropTypes.string,
});

export const DealDocuments = PropTypes.arrayOf(DealDocument.isRequired);

function lazyFunction(f) {
    return function () {
        return f().apply(this, arguments);
    };
}

//definitions for components
export const QuotationOrderItem = PropTypes.shape({
    id_item: PropTypes.number.isRequired,
    quotations: PropTypes.number,
});

export const QuotationOrderItems = PropTypes.arrayOf(QuotationOrderItem.isRequired);

export const FormHOCPropsObject = {
    createSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    errorCode: PropTypes.number,
};

export const CartOrderFormValues = PropTypes.shape({
    userId: PropTypes.number,
    invoiceAddressId: PropTypes.number,
    deliveryAddressId: PropTypes.number,
    trade: Trade,
    transportTypeId: PropTypes.number,
    paymentMethodId: PropTypes.number,
    customerReference: PropTypes.string,
    customerNotices: PropTypes.string,
    dealerNotices: PropTypes.string,
    isDivisible: PropTypes.number,
});

export const BackupStatus = PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    last_record: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
});

export const TransferType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    trade: PropTypes.string.isRequired,
    class: PropTypes.string.isRequired,
    id_invoice_type: PropTypes.string,
});

export const TransferTypes = PropTypes.arrayOf(TransferType.isRequired);

export const Language = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

export const HistoryMethodArg = PropTypes.shape({
    key: PropTypes.string.isRequired,
    val: Decimal,
    label: PropTypes.string,
    args: PropTypes.arrayOf(PropTypes.object),
});

export const ObjectHistoryMethodObject = {
    id: PropTypes.number.isRequired,
    id_who: PropTypes.number.isRequired,
    who: PropTypes.string.isRequired,
    access_time: PropTypes.string.isRequired,
    ip_address: PropTypes.string.isRequired,
    class: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    args: PropTypes.arrayOf(HistoryMethodArg).isRequired,
};

export const ObjectHistoryMethod = PropTypes.shape(ObjectHistoryMethodObject);

export const ObjectHistory = PropTypes.arrayOf(ObjectHistoryMethod.isRequired);

export const CustomerNoteObject = {
    id: PropTypes.number.isRequired,
    id_parent: PropTypes.number,
    id_root: PropTypes.number.isRequired,
    id_customer: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    id_dealer: PropTypes.number.isRequired,
    dealer: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    edited_at: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export const CustomerNote = PropTypes.shape(CustomerNoteObject);

export const AnalysisCols = PropTypes.arrayOf(PropTypes.shape({
    col: PropTypes.string.isRequired,
    label_col: PropTypes.string,
    caption: PropTypes.string.isRequired,
}));

export const AnalysisType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    rights: PropTypes.number,
    key_cols: AnalysisCols.isRequired,
    data_cols: AnalysisCols.isRequired,
});

export const AnalysisResult = PropTypes.shape({
    key_cols: AnalysisCols.isRequired,
    data_cols: AnalysisCols.isRequired,
    samples: PropTypes.arrayOf(PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string.isRequired,
    })),
    rows: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // empty array
        data: PropTypes.arrayOf(PropTypes.object).isRequired
    })).isRequired,
    filter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // empty array
    label: PropTypes.string.isRequired,
});

export const Vehicle = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    id_user: PropTypes.number,
    user: PropTypes.string,
    is_active: PropTypes.oneOf([0, 1]).isRequired,
    start_km: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    start_fuel_quantity: Decimal.isRequired,
    start_fuel_value: PropTypes.string.isRequired,
    closed_until: PropTypes.string.isRequired,
    fuel_consumption_local: PropTypes.string.isRequired,
    fuel_consumption_away: PropTypes.string.isRequired,
});

export const Vehicles = PropTypes.arrayOf(Vehicle.isRequired);

export const FlagType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

export const EventType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

export const EventTypes = PropTypes.arrayOf(EventType.isRequired);

export const EventParticipant = PropTypes.shape({
    id_event: PropTypes.number.isRequired,
    id_user: PropTypes.number.isRequired,
    id_customer: PropTypes.number.isRequired,
    customer: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    e_mail: PropTypes.string,
    phone: PropTypes.string,
    position: PropTypes.string,
});

export const EventObj = PropTypes.shape({
    id: PropTypes.number.isRequired,
    id_type: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    start_at: PropTypes.string.isRequired,
    end_at: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    place: PropTypes.string,
    id_customer: PropTypes.number,
    customer: PropTypes.string,
    id_address: PropTypes.number,
    id_vehicle: PropTypes.number,
    vehicle: PropTypes.string,
    vehicle_description: PropTypes.string,
    start_km: PropTypes.number,
    end_km: PropTypes.number,
    distance: PropTypes.number,
    start_fuel_quantity: Decimal,
    start_fuel_value: PropTypes.string,
    end_fuel_quantity: Decimal,
    end_fuel_value: PropTypes.string,
    fuel_consumption: PropTypes.string,
    refuel_quantity: Decimal,
    refuel_price: PropTypes.string,
    refuel_tax_rate: Decimal,
    refuel_price_brutto: PropTypes.string,
    refuel_at: PropTypes.string,
    refuel_place: PropTypes.string,
    participants: PropTypes.arrayOf(EventParticipant.isRequired).isRequired,
});

export const EventObjs = PropTypes.arrayOf(EventObj.isRequired);

export const Alert = PropTypes.shape({
    id: PropTypes.number.isRequired,
    id_type: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    tim: PropTypes.string.isRequired,
    msg: PropTypes.string,
    id_creator: PropTypes.number.isRequired,
    creator: PropTypes.string.isRequired,
    class: PropTypes.string.isRequired,
    id_obj: PropTypes.number,
    id_customer: PropTypes.number,
    customer: PropTypes.string,
    id_salesman: PropTypes.number,
    salesman: PropTypes.string,
    id_salesman2: PropTypes.number,
    salesman2: PropTypes.string,
    id_addressee: PropTypes.number,
    addressee: PropTypes.string,
    min_access_level: PropTypes.number,
    min_rights: PropTypes.string,
    is_active: PropTypes.oneOf([0, 1]).isRequired,
    method: PropTypes.string,
    args: PropTypes.string,
    id_worker: PropTypes.number,
    worker: PropTypes.string,
    closed_at: PropTypes.string,
    is_executed: PropTypes.oneOf([0, 1]).isRequired,
});

export const Alerts = PropTypes.arrayOf(Alert.isRequired);

export const DashboardItem = PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    analysis: PropTypes.shape({
        type: PropTypes.string.isRequired,
        data_cols: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        samples_count: PropTypes.number,
        sampling_period: PropTypes.string,
        user_col: PropTypes.string,
        from: PropTypes.string,
        to: PropTypes.string,
    }),
    history: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        val: Decimal
    })),
    units: PropTypes.string,
    current_month: PropTypes.number,
    current_year: PropTypes.number,
    target_month: PropTypes.string,
    target_year: PropTypes.string,
});

export const Task = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    is_active: PropTypes.oneOf([0, 1]).isRequired,
    is_binary: PropTypes.oneOf([0, 1]).isRequired,
    is_editable: PropTypes.oneOf([0, 1]).isRequired,
    variable: PropTypes.string,
});

export const Assignment = PropTypes.shape({
    id: PropTypes.number.isRequired,
    id_user: PropTypes.number.isRequired,
    user: PropTypes.string.isRequired,
    id_task: PropTypes.number.isRequired,
    task: PropTypes.string.isRequired,
    task_description: PropTypes.string,
    task_variable: PropTypes.string,
    at: PropTypes.string.isRequired,
    yr: PropTypes.number.isRequired,
    mon: PropTypes.number.isRequired,
    is_editable: PropTypes.oneOf([0, 1]).isRequired,
    is_binary: PropTypes.oneOf([0, 1]).isRequired,
    prev_saldo: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    result: PropTypes.string,
    saldo: PropTypes.string,
    bonus_ratio: PropTypes.string.isRequired,
    bonus_min: PropTypes.string,
    bonus_max: PropTypes.string,
    bonus_available: PropTypes.string,
    bonus: PropTypes.string,
    signed_at: PropTypes.string,
    id_manager: PropTypes.number,
    manager: PropTypes.string,
    notices: PropTypes.string,
});

export const Assignments = PropTypes.arrayOf(Assignment.isRequired);

export const Wages = PropTypes.shape({
    employees: PropTypes.arrayOf(PropTypes.shape({
        id_user: PropTypes.number.isRequired,
        user: PropTypes.string.isRequired,
        at: PropTypes.string.isRequired,
        yr: PropTypes.number.isRequired,
        mon: PropTypes.number.isRequired,
        netto: Decimal.isRequired,
        brutto: Decimal.isRequired,
        total: Decimal.isRequired,
        sum_bonus: Decimal.isRequired,
        bonuses: PropTypes.arrayOf(PropTypes.shape({
            category: PropTypes.string,
            amount: Decimal.isRequired
        })),
    })),
    summary: PropTypes.arrayOf(PropTypes.shape({
        netto: Decimal.isRequired,
        brutto: Decimal.isRequired,
        total: Decimal.isRequired,
        sum_bonus: Decimal.isRequired,
        bonuses: PropTypes.arrayOf(PropTypes.shape({
            category: PropTypes.string,
            amount: Decimal.isRequired
        })),
    })),
});
