import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';

/**
 * Component that enables input text. onChange event is fired only
 * when return is pressed or when focus is lost.
 *
 * @fero
 */

class InputText extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.string,
        type: PropTypes.string,
        autoFocus: PropTypes.bool,
        allowClear: PropTypes.bool,
        sanitizer: PropTypes.func,
        changeImmediately: PropTypes.bool,
    };

    static defaultProps = {
        type: "text",
        autoFocus: false,
        changeImmediately: false
    };

    constructor(props) {
        super(props);
        this.state = {
            currentValue: props.value,
        };
    }

    componentDidMount() {
        const {autoFocus} = this.props;
        if(autoFocus)
        {
            setTimeout(() => {
                this.focus();
            }, 0)
        }
    }

    componentDidUpdate(prevProps) {
        const {value} = this.props;
        const {value: prevValue} = prevProps;
        if(value != prevValue)
            this.setState({currentValue: value});
    }

    focus() {
        if(this.input != null && this.input.focus != null) 
            this.input.focus();
    }

    onChanged = (ev) => {
        const {onChange, allowClear, sanitizer, changeImmediately} = this.props;
        const value = ev != null && ev.target != null ? ev.target.value : null;
        const valueSanitized = sanitizer != null ? sanitizer(value) : value;
        const valueChecked = valueSanitized != "" ? valueSanitized : null;
        this.setState({currentValue: valueChecked});

        if(changeImmediately || (valueChecked == null && allowClear))
        {
            if(onChange != null)
                onChange(valueChecked);
        }
    };

    onBlur = () => {
        const {currentValue} = this.state;
        const {value, onChange} = this.props;
        if(value != currentValue && onChange != null)
            onChange(currentValue);
    };

    render() {
        const {value, onChange, type, autoFocus, placeholder, changeImmediately, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile, ...props} = this.props;
        const {currentValue} = this.state;
        
        return <Input
            {...props}
            placeholder={placeholder}
            onChange={this.onChanged}
            onBlur={this.onBlur}
            onPressEnter={this.onBlur}
            type={type}
            value={currentValue}
            ref={node => this.input = node}
        />;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(InputText);