import { fetchPostJson, fetchGetJson } from '../lib/fetch';
import { API_ENDPOINTS } from '../constants/apiEndpoints';

/**
 * @fero
 */

export const createFetchAllManufacturers = () => () => {
    return fetchGetJson(API_ENDPOINTS.MANUFACTURERS_ALL);
};

export const createFetchManufacturerAdd = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.MANUFACTURERS_ADD, {}, formParams);
};

export const createFetchManufacturerEdit = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.MANUFACTURERS_EDIT, {}, formParams);
};

export const createFetchManufacturerRemove = (manufacturerId) => () => {
    return fetchPostJson(API_ENDPOINTS.MANUFACTURERS_REMOVE, {}, {id: manufacturerId});
};

export const createFetchFlags = () => () => {
    return fetchGetJson(API_ENDPOINTS.FLAG_ALL);
};

export const createFetchFlagAdd = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.FLAG_ADD, {}, formParams);
};

export const createFetchFlagEdit = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.FLAG_EDIT, {}, formParams);
};

export const createFetchFlagRemove = (flagId) => () => {
    return fetchPostJson(API_ENDPOINTS.FLAG_REMOVE, {}, {id: flagId});
};

export const createFetchDepartmentAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.DEPARTMENT_ALL, params);
};


export const createFetchDepartmentAdd = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.DEPARTMENT_ADD, {}, formParams);
};

export const createFetchDepartmentEdit = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.DEPARTMENT_EDIT, {}, formParams);
};

export const createFetchDepartmentRemove = (departmentId) => () => {
    return fetchPostJson(API_ENDPOINTS.DEPARTMENT_REMOVE, {}, {id: departmentId});
};

export const createFetchFinstatDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.FINSTAT_DETAILS, params);
};

export const createFetchCountryAll = () => () => {
    return fetchGetJson(API_ENDPOINTS.COUNTRY_ALL);
};

export const createFetchCategoryAll = () => () => {
    return fetchGetJson(API_ENDPOINTS.CATEGORY_ALL);
};

export const createFetchCategoryTree = () => () => {
    return fetchGetJson(API_ENDPOINTS.CATEGORY_TREE);
};

export const createFetchCategoryDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.CATEGORY_DETAILS, params);
};

export const createFetchCategoryAdd = () => (addCategory) => {
    return fetchPostJson(API_ENDPOINTS.CATEGORY_ADD, {}, addCategory);
};

export const createFetchCategoryEdit = () => (editCategory) => {
    return fetchPostJson(API_ENDPOINTS.CATEGORY_EDIT, {}, editCategory);
};

export const createFetchCategoryRemove = (categoryId) => () => {
    return fetchPostJson(API_ENDPOINTS.CATEGORY_REMOVE, {}, {
        id: categoryId,
    });
};

export const createFetchDiscountGroup = () => () => {
    return fetchGetJson(API_ENDPOINTS.DISCOUNT_GROUPS);
};

export const createFetchDiscountTree = () => () => {
    return fetchGetJson(API_ENDPOINTS.DISCOUNT_TREE);
};

export const createFetchDiscountRules = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.DISCOUNT_RULES, params);
};

export const createFetchDiscountEditRule = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.DISCOUNT_EDIT_RULE, {}, params);
};

export const createFetchDiscountRemoveRule = (customerId, discountGroupId) => () => {
    return fetchPostJson(API_ENDPOINTS.DISCOUNT_REMOVE_RULE, {}, {
        id_customer: customerId, 
        id_discount_group: discountGroupId,
    });
};

export const createFetchDiscountGroupRemove = (discountGroupId) => () => {
    return fetchPostJson(API_ENDPOINTS.DISCOUNT_REMOVE_GROUP, {}, {
        id: discountGroupId,
    });
};

export const createFetchDiscountAddGroup = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.DISCOUNT_ADD_GROUP, {}, params);
};

export const createFetchDiscountEditGroup = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.DISCOUNT_EDIT_GROUP, {}, params);
};

export const createFetchSessionSettings = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.SETTINGS_SESSION, {}, params);
};

export const createFetchRightsAll = () => () => {
    return fetchGetJson(API_ENDPOINTS.RIGHTS_ALL);
};

export const createFetchCompanyAddresses = () => () => {
    return fetchGetJson(API_ENDPOINTS.COMPANY_ADDRESSES);
};

export const createFetchCompanyCommercialTerms = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.COMPANY_COMMERCIAL_TERMS, params);
};

export const createFetchCompanyEditCommercialTerms = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.COMPANY_EDIT_COMMERCIAL_TERMS, {}, params);
};

export const createFetchCompanyReturnConditions = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.COMPANY_RETURN_CONDITIONS, params);
};

export const createFetchCompanyEditReturnConditions = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.COMPANY_EDIT_RETURN_CONDITIONS, {}, params);
};

export const createFetchCompanyPrivacyPolicy = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.COMPANY_PRIVACY_POLICY, params);
};

export const createFetchCompanyEditPrivacyPolicy = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.COMPANY_EDIT_PRIVACY_POLICY, {}, params);
};

export const createFetchCustomerAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.CUSTOMER_ALL, params);
};

export const createFetchCustomerDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.CUSTOMER_DETAILS, params);
};

export const createFetchCustomerTypes = () => () => {
    return fetchGetJson(API_ENDPOINTS.CUSTOMER_TYPES);
};

export const createFetchCustomerGroups = () => () => {
    return fetchGetJson(API_ENDPOINTS.CUSTOMER_GROUPS);
};

export const createFetchCustomerAddGroup = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CUSTOMER_ADD_GROUP, {}, params);
};

export const createFetchCustomerEditGroup = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CUSTOMER_EDIT_GROUP, {}, params);
};

export const createFetchCustomerRemoveGroup = (groupId) => () => {
    return fetchPostJson(API_ENDPOINTS.CUSTOMER_REMOVE_GROUP, {}, {
        id: groupId,
    });
};

export const createFetchCustomerAdd = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CUSTOMER_ADD, {}, params);
};

export const createFetchCustomerEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CUSTOMER_EDIT, {}, params);
};

export const createFetchCustomerRemove = (customerId) => () => {
    return fetchPostJson(API_ENDPOINTS.CUSTOMER_REMOVE, {}, {
        id: customerId,
    });
};

export const createFetchCustomerAddresses = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.CUSTOMER_ADDRESSES, params);
};

export const createFetchProductSearch = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_SEARCH, params);
};

export const createFetchProductIndex = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_INDEX, {}, params);
};

export const createFetchProductProducts = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_PRODUCTS, params);
};

export const createFetchProductFilters = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_FILTERS, params);
};

export const createFetchProductStockOptions = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_STOCK_OPTIONS, params);
};

export const createFetchProductAdd = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_ADD, {}, params);
};

export const createFetchProductEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_EDIT, {}, params);
};

export const createFetchProductEditQuantityUnit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_EDIT_QUANTITY_UNIT, {}, params);
};

export const createFetchProductRemove = (productId) => () => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_REMOVE, {}, {
        id: productId,
    });
};

export const createFetchProductRefreshStock = (productId) => () => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_REFRESH_STOCK, {}, {
        id: productId,
    });
};

export const createFetchProductEditSpecialPrice = () => (special_price) => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_EDIT_SPECIAL_PRICE, {}, special_price);
};

export const createFetchProductRemoveSpecialPrice = (productId, customerId) => () => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_REMOVE_SPECIAL_PRICE, {}, {
        id_product: productId,
        id_customer: customerId
    });
};

export const createFetchProductPrice = (productId, customerId) => () => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_PRICE, {
        id_product: productId,
        id_customer: customerId
    });
};

export const createFetchProductExplainPrice = (productId, customerId) => () => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_EXPLAIN_PRICE, {
        id_product: productId,
        id_customer: customerId
    });
};

export const createFetchProductDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_DETAILS, params);
};

export const createFetchProductExplainAvail = (productId) => () => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_EXPLAIN_AVAILABILITY, {
        id_product: productId,
    });
}

export const createFetchProductTransfers = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_TRANSFERS, params);
};

export const createFetchProductOrders = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_ORDERS, params);
};

export const createFetchProductQuotations = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_QUOTATIONS, params);
};

export const createFetchProductRequests = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_REQUESTS, params);
};

export const createFetchProductSalesHistory = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_SALES_HISTORY, params);
};

export const createFetchProductStockDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_STOCK_DETAILS, params);
};

export const createFetchProductPurchasePrices = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_PURCHASE_PRICES, params);
}

export const createFetchProductEditHtml = (productId) => (htmlString) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_EDIT_HTML, {
        id: productId,
        description_html: htmlString,
    });
};

export const createFetchProductEditParam = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_EDIT_PARAM, params);
};

export const createFetchProductEditCustomerCode = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_EDIT_CUSTOMER_CODE, {}, params);
};

export const createFetchProductRemoveCustomerCode = (productId, customerId) => () => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_REMOVE_CUSTOM_CODE, {}, {
        id_product: productId,
        id_customer: customerId
    });
};

export const createFetchProductEditBarcode = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_EDIT_BARCODE, {}, params);
};

export const createFetchProductCrossReferences = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_CROSS_REFERENCES, params);
};

export const createFetchProductCompetitors = () => () => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_COMPETITORS);
};

export const createFetchProductReplacements = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_REPLACEMENTS, params);
};

export const createFetchProductAddReplacement = (productId, replacementId) => () => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_ADD_REPLACEMENT, {}, {
        id_product: productId,
        id_replacement: replacementId,
    });
};

export const createFetchProductRemoveReplacement = (productId, replacementId) => () => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_REMOVE_REPLACEMENT, {}, {
        id_product: productId,
        id_replacement: replacementId,
    });
};

export const createFetchProductRelatives = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_RELATIVES, params);
};

export const createFetchProductAddRelative = (productId, relativeId) => () => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_ADD_RELATIVE, {}, {
        id_product: productId,
        id_relative: relativeId,
    });
};

export const createFetchProductRemoveRelative = (productId, relativeId) => () => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_REMOVE_RELATIVE, {}, {
        id_product: productId,
        id_relative: relativeId,
    });
};

export const createFetchProductVariants = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_VARIANTS, params);
};

export const createFetchProductAddVariant = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_ADD_VARIANT, {}, params);
};

export const createFetchProductRemoveVariant = (productId, variantId) => () => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_REMOVE_VARIANT, {}, {
        id_product: productId,
        id_variant: variantId,
    });
};

export const createFetchParameterAll = () => () => {
    return fetchGetJson(API_ENDPOINTS.PARAMETER_ALL);
};

export const createFetchParameterAdd = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.PARAMETER_ADD, {}, params);
};

export const createFetchParameterEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.PARAMETER_EDIT, {}, params);
};

export const createFetchParameterValues = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.PARAMETER_VALUES, {}, params);
};

export const createFetchParameterDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PARAMETER_DETAILS, params);
};

export const createFetchCartCurrent = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.CART_CURRENT, params);
};

export const createFetchDashboardVariables = () => () => {
    return fetchGetJson(API_ENDPOINTS.DASHBOARD_VARIABLES);
};

export const createFetchDashboardItem = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.DASHBOARD_ITEM, {}, params);
};

export const createFetchDashboardItems = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.DASHBOARD_ITEMS, params);
};

export const createFetchTaskAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.TASK_ALL, params);
};

export const createFetchTaskAdd = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.TASK_ADD, {}, params);
};

export const createFetchTaskEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.TASK_EDIT, {}, params);
};

export const createFetchTaskRemove = (taskId) => () => {
    return fetchPostJson(API_ENDPOINTS.PRODUCT_REMOVE_VARIANT, {}, { id: taskId });
};

export const createFetchWageAll = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.WAGE_ALL, {}, params);
};

export const createFetchWagesSummary = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.WAGE_SUMMARY, {}, params);
};

export const createFetchWageEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.WAGE_EDIT, {}, params);
};

export const createFetchAssignmentAll = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ASSIGNMENT_ALL, {}, params);
};

export const createFetchAssignmentAdd = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ASSIGNMENT_ADD, {}, params);
};

export const createFetchAssignmentEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ASSIGNMENT_EDIT, {}, params);
};

export const createFetchAssignmentReload = (assignmentId, pin) => () => {
    return fetchPostJson(API_ENDPOINTS.ASSIGNMENT_RELOAD, {}, {id: assignmentId, pin: pin});
};

export const createFetchAssignmentSign = (assignmentId, pin) => () => {
    return fetchPostJson(API_ENDPOINTS.ASSIGNMENT_SIGN, {}, {id: assignmentId, pin: pin});
};

export const createFetchAssignmentReopen = (assignmentId, pin) => () => {
    return fetchPostJson(API_ENDPOINTS.ASSIGNMENT_REOPEN, {}, {id: assignmentId, pin: pin});
};

export const createFetchAssignmentRemove = (assignmentId, pin) => () => {
    return fetchPostJson(API_ENDPOINTS.ASSIGNMENT_REMOVE, {}, {id: assignmentId, pin: pin});
};

export const createFetchCartAddItems = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CART_ADD_ITEMS, {}, params);
};

export const createFetchCartAddItem = (productId, quantity, customerId, packageTypeId, stockId) => () => {
    return fetchPostJson(API_ENDPOINTS.CART_ADD_ITEM, {}, {
        id_product: productId,
        quantity: quantity,
        id_customer: customerId,
        id_package_type: packageTypeId,
        id_stock: stockId,
    });
};

export const createFetchCartAddItemDealer = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CART_ADD_ITEM, {}, params);
};

export const createFetchCartEditItem = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CART_EDIT_ITEM, {}, params);
};

export const createFetchCartRemoveItems = (customerId, itemId) => () => {
    return fetchPostJson(API_ENDPOINTS.CART_REMOVE_ITEMS, {}, {
        id_customer: customerId,
        id_item: itemId,
    });
};

export const createFetchCartMakeOrder = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_FROM_CART, {}, params);
};

export const createFetchCartMakeQuotation = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_FROM_CART, {}, params);
};

export const createFetchCartMakeRequest = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.REQUEST_FROM_CART, {}, params);
};

export const createFetchCartSave = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CART_SAVE, {}, params);
};

export const createFetchCartSaveAs = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CART_SAVE_AS, {}, params);
};

export const createFetchCartMove = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CART_MOVE, {}, params);
};

export const createFetchCartShare = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CART_SHARE, {}, params);
};

export const createFetchCartEditRequestedAt = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.CART_EDIT_REQUESTED_AT, {}, params);
};

export const createFetchPredictionMethods = () => () => {
    return fetchGetJson(API_ENDPOINTS.PREDICTION_METHODS);
};

export const createFetchPredictionFillCart = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.PREDICTION_FILL_CART, {}, params);
};

export const createFetchUserEmployees = () => () => {
    return fetchGetJson(API_ENDPOINTS.USER_EMPLOYEES);
};

export const createFetchUserSupervisors = () => () => {
    return fetchGetJson(API_ENDPOINTS.USER_SUPERVISORS);
};

export const createUserLogin = () => (e_mail, password) => {
    return fetchPostJson(API_ENDPOINTS.USER_LOGIN, {}, {
        e_mail: e_mail,
        password: password,
    })
};

export const createUserForgottenPassword = () => (e_mail) => {
    return fetchPostJson(API_ENDPOINTS.USER_FORGOTTEN_PASSWORD, {}, {
        e_mail: e_mail,
    })
};

export const createUserSendLoginToken = () => (e_mail) => {
    return fetchPostJson(API_ENDPOINTS.USER_SEND_LOGIN_TOKEN, {}, {
        e_mail: e_mail,
    })
};

export const createUserLogout = () => () => {
    return fetchGetJson(API_ENDPOINTS.USER_LOGOUT, {})
};

export const createUserRegister = () => (user) => {
    return fetchPostJson(API_ENDPOINTS.USER_ADD, {}, user)
};

export const createUserChangePassword = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.USER_CHANGE_PASSWORD, {}, params);
};

export const createUserActivate = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.USER_ACTIVATE, {}, params);
};

export const createUserSendActivationEmail = () => () => {
    return fetchPostJson(API_ENDPOINTS.USER_SEND_ACTIVATION_EMAIL, {}, {});
};

export const createUserSendPinChangeEmail = () => () => {
    return fetchPostJson(API_ENDPOINTS.USER_SEND_PIN_CHANGE_EMAIL, {}, {});
};

export const createFetchUserCurrent = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.USER_CURRENT, params)
};

export const createFetchUserAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.USER_ALL, params);
};

export const createFetchUserDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.USER_DETAILS, params);
};

export const createFetchUserEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.USER_EDIT, {}, params);
};

export const createFetchUserRemove = (userId) => () => {
    return fetchPostJson(API_ENDPOINTS.USER_REMOVE, {}, {
        id: userId,
    });
};

export const createFetchUserGenerateApiKey = (userId) => () => {
    return fetchPostJson(API_ENDPOINTS.USER_GENERATE_API_KEY, {}, {
        id_user: userId,
    });
};

export const createFetchUserAddresses = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ACCOUNT_ADDRESSES, params);
};

export const createFetchAddAddress = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ADDRESS_ADD, {}, params);
};

export const createFetchUserRemoveAddress = (addressId) => () => {
    return fetchPostJson(API_ENDPOINTS.ADDRESS_REMOVE, {}, {
        id: addressId,
    });
};

export const createFetchEditAddress = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ADDRESS_EDIT, {}, params);
};

export const createFetchPaymentMethods = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PAYMENT_METHODS, params);
};

export const createFetchTransportTypes = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.TRANSPORT_TYPES, params);
};

export const createFetchTransportPickupPoints = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.TRANSPORT_PICKUP_POINTS, params);
};

export const createFetchOrderAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ORDER_ALL, params);
};

export const createFetchOrderStates = () => () => {
    return fetchGetJson(API_ENDPOINTS.ORDER_STATES);
};

export const createFetchOrderLines = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ORDER_LINES, params);
};

export const createFetchOrderLineStates = () => () => {
    return fetchGetJson(API_ENDPOINTS.ORDER_LINE_STATES);
};

export const createFetchOrderLoadToCart = (orderId) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_LOAD_TO_CART, {}, {
        id: orderId,
    })
};

export const createFetchInvoiceAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.INVOICE_ALL, params);
};

export const createFetchInvoiceClasses = () => () => {
    return fetchGetJson(API_ENDPOINTS.INVOICE_CLASSES);
};

export const createFetchInvoiceDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.INVOICE_DETAILS, params);
};

export const createFetchRequestAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.REQUEST_ALL, params);
};

export const createFetchRequestStates = () => () => {
    return fetchGetJson(API_ENDPOINTS.REQUEST_STATES);
};

export const createFetchRequestDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.REQUEST_DETAILS, params);
};

export const createFetchRequestPreview = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.REQUEST_PREVIEW, params);
};

export const createFetchRequestSend = (requestId) => () => {
    return fetchPostJson(API_ENDPOINTS.REQUEST_SEND, {}, {
        id: requestId,
    });
};

export const createFetchRequestAdd = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.REQUEST_ADD, {}, params);
};

export const createFetchRequestRemove = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.REQUEST_REMOVE, {}, params);
};

export const createFetchRequestAddItem = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.REQUEST_ADD_ITEM, {}, params);
};

export const createFetchRequestEditItem = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.REQUEST_EDIT_ITEM, {}, params);
};

export const createFetchRequestFromIncoming = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.REQUEST_FROM_INCOMING, {}, params);
};

export const createFetchRequestRemoveItem = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.REQUEST_REMOVE_ITEM, {}, params);
};

export const createFetchRequestMakeQuotation = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_FROM_REQUEST, {}, params);
};

export const createFetchRequestEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.REQUEST_EDIT, {}, params);
};

export const createFetchRequestLoadToCart = (requestId) => () => {
    return fetchPostJson(API_ENDPOINTS.REQUEST_LOAD_TO_CART, {}, {
        id: requestId,
    });
};

export const createFetchQuotationAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.QUOTATION_ALL, params);
};

export const createFetchQuotationStates = () => () => {
    return fetchGetJson(API_ENDPOINTS.QUOTATION_STATES);
};

export const createFetchQuotationDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.QUOTATION_DETAILS, params);
};

export const createFetchQuotationPreview = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.QUOTATION_PREVIEW, params);
};

export const createFetchQuotationDivisibility = () => () => {
    return fetchGetJson(API_ENDPOINTS.QUOTATION_DIVISIBILITY, {});
};

export const createFetchQuotationEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_EDIT, {}, params);
};

export const createFetchQuotationFitPrices = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_FIT_PRICES, {}, params);
};

export const createFetchQuotationLoadToCart = (quotationId, items) => () => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_LOAD_TO_CART, {}, {
        id: quotationId,
        items: items,
    });
};

export const createFetchQuotationFromIncoming = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_FROM_INCOMING, {}, params);
};

export const createFetchQuotationSave = (quotationId) => () => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_SEND, {}, {
        id: quotationId
    });
};

export const createFetchQuotationSend = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_SEND, {}, params);
};

export const createFetchQuotationRemove = (quotationId) => () => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_REMOVE, {}, {
        id: quotationId
    });
};

export const createFetchQuotationReopen = (quotationId) => () => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_REOPEN, {}, {
        id: quotationId
    });
};

export const createFetchQuotationRemoveItem = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_REMOVE_ITEM, {}, params);
};

export const createFetchQuotationCopy = (quotationId) => () => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_COPY, {}, {
        id: quotationId,
    });
};

export const createFetchQuotationAddItem = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_ADD_ITEM, {}, params);
};

export const createFetchQuotationEditItem = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.QUOTATION_EDIT_ITEM, {}, params);
};

export const createFetchQuotationMakeOrder = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_FROM_QUOTATION, {}, params);
};

export const createFetchUploadFiles = () => () => {
    return fetchGetJson(API_ENDPOINTS.UPLOAD_FILES);
};

export const createFetchOrderDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ORDER_DETAILS, params);
};

export const createFetchOrderPreview = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ORDER_PREVIEW, params);
};

export const createFetchOrderRemove = (orderId) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_REMOVE, {}, {
        id: orderId,
    });
};

export const createFetchOrderResetPrices = (orderId) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_RESET_PRICES, {}, {
        id: orderId,
    });
};

export const createFetchOrderCancel = (orderId, itemId) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_CANCEL, {}, {
        id_order: orderId,
        id_item: itemId,
    });
};

export const createFetchOrderDispatch = (orderId) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_DISPATCH, {}, {
        id: orderId,
    });
};

export const createFetchOrderSplit = (orderId) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_SPLIT, {}, {
        id: orderId,
    });
};

export const createFetchOrderAdd = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_ADD, {}, params);
};

export const createFetchOrderFromIncoming = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_FROM_INCOMING, {}, params);
};

export const createFetchOrderEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ORDER_EDIT, {}, params);
};

export const createFetchOrderFitPrices = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ORDER_FIT_PRICES, {}, params);
};

export const createFetchOrderAddItem = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_ADD_ITEM, {}, params);
};

export const createFetchOrderEditItem = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ORDER_EDIT_ITEM, {}, params);
};

export const createFetchOrderRemoveItem = (orderId, itemId) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_REMOVE_ITEM, {}, {
        id_order: orderId,
        id_item: itemId,
    });
};

export const createFetchOrderAddReservation = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ORDER_ADD_RESERVATION, {}, params);
};

export const createFetchOrderRemoveReservation = (orderId, reservationItemId) => () => {
    return fetchPostJson(API_ENDPOINTS.ORDER_REMOVE_RESERVATION, {}, {
        id_order: orderId,
        id_reservation_item: reservationItemId,
    });
};

export const createFetchOrderSend = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ORDER_SEND, {}, params);
};

export const createFetchNewsAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.NEWS_ALL, params);
};

export const createFetchNewsDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.NEWS_DETAILS, params);
};

export const createFetchNewsAdd = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.NEWS_ADD, {}, params);
};

export const createFetchNewsEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.NEWS_EDIT, {}, params);
};

export const createFetchNewsEditHtml = (newsId) => (htmlString) => {
    return fetchPostJson(API_ENDPOINTS.NEWS_EDIT, {}, {
        id: newsId,
        html: htmlString,
    });
};

export const createFetchNewsRemove = (newsId) => () => {
    return fetchPostJson(API_ENDPOINTS.NEWS_REMOVE, {}, {
        id: newsId,
    });
};

export const createFetchShoppingListAll = () => () => {
    return fetchGetJson(API_ENDPOINTS.SHOPPING_LIST_ALL, {});
};

export const createFetchShoppingListDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.SHOPPING_LIST_DETAILS, params);
};

export const createFetchShoppingListEditItem = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.SHOPPING_LIST_EDIT_ITEM, {}, params);
};

export const createFetchShoppingListRemove = (listId) => () => {
    return fetchPostJson(API_ENDPOINTS.SHOPPING_LIST_REMOVE, {}, {
        id: listId,
    });
};

export const createFetchShoppingListEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.SHOPPING_LIST_EDIT, {}, params);
};

export const createFetchShoppingListRemoveItems = (listId, itemId) => () => {
    return fetchPostJson(API_ENDPOINTS.SHOPPING_LIST_REMOVE_ITEMS, {}, {
        id_list: listId,
        id_item: itemId, 
    });
};

export const createFetchShoppingListLoadItems = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.SHOPPING_LIST_LOAD_ITEMS, {}, params);
};

export const createFetchShoppingListShare = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.SHOPPING_LIST_SHARE, {}, params);
};

export const createFetchReportAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.REPORT_ALL, params);
};

export const createFetchReportColumns = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.REPORT_COLUMNS, {
        root: params.rootName,
        depth: params.depth,
    });
};

export const createFetchReportOperations = () => () => {
    return fetchGetJson(API_ENDPOINTS.REPORT_OPERATIONS, {});
};

export const createFetchReportTables = () => () => {
    return fetchGetJson(API_ENDPOINTS.REPORT_TABLES, {});
};

export const createFetchReportAdd = (report) => () => {
    return fetchPostJson(API_ENDPOINTS.REPORT_ADD, {}, report);
};

export const createFetchReportEdit = (reportId, report) => () =>  {
    return fetchPostJson(API_ENDPOINTS.REPORT_EDIT, {}, {
        ...report,
        id: reportId,
    });
};

export const createFetchReportRun = () => (reportId) => {
    return fetchPostJson(API_ENDPOINTS.REPORT_RUN, {}, {
        id: reportId
    });
};

export const createFetchReportRemove = (reportId) => () => {
    return fetchPostJson(API_ENDPOINTS.REPORT_REMOVE, {}, {
        id: reportId
    });
};

export const createFetchAttachmentAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ATTACHMENT_ALL, params);
};

export const createFetchAttachmentAdd = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ATTACHMENT_ADD, {}, params);
};

export const createFetchAttachmentEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ATTACHMENT_EDIT, {}, params);
};

export const createFetchAttachmentRemove = (attachmentId) => () => {
    return fetchPostJson(API_ENDPOINTS.ATTACHMENT_REMOVE, {}, {
        id: attachmentId,
    });
};

export const createFetchStockAll = () => () => {
    return fetchGetJson(API_ENDPOINTS.STOCK_ALL, {})
};

export const createFetchSettingsAll = () => () => {
    return fetchGetJson(API_ENDPOINTS.SETTINGS_ALL, {})
};

export const createFetchCurrencyAll = () => () => {
    return fetchGetJson(API_ENDPOINTS.CURRENCY_ALL, {});
};

export const createFetchFormAll = () => () => {
    return fetchGetJson(API_ENDPOINTS.FORM_ALL, {});
};

export const createFetchFormDetails = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.FORM_DETAILS, {}, params);
};

export const createFetchFormRun = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.FORM_RUN, {}, params);
};

export const createFetchFormAdd = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.FORM_ADD, {}, params);
};

export const createFetchFormEdit = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.FORM_EDIT, {}, params);
};

export const createFetchFormRemove = (formId) => () => {
    return fetchPostJson(API_ENDPOINTS.FORM_REMOVE, {}, {
        id: formId,
    });
};

export const createFetchChannelAll = () => () => {
    return fetchGetJson(API_ENDPOINTS.CHANNEL_ALL, {});
};

export const createFetchDealDocuments = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.DEAL_DOCUMENTS, params);
};

export const createFetchDealAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.DEAL_ALL, params);
};

export const createFetchBackupStatus = () => () => {
    return fetchGetJson(API_ENDPOINTS.BACKUP_STATUS, {});
};

export const createFetchBackupSave = () => () => {
    return fetchPostJson(API_ENDPOINTS.BACKUP_SAVE, {}, {});
};

export const createFetchBackupSaveJournal = () => () => {
    return fetchPostJson(API_ENDPOINTS.BACKUP_SAVE_JOURNAL, {}, {});
};

export const createFetchBackupRestore = () => () => {
    return fetchPostJson(API_ENDPOINTS.BACKUP_RESTORE, {}, {});
};

export const createFetchTransferTypes = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.TRANSFER_TYPES, params);
};

export const createFetchLanguageAll = () => () => {
    return fetchGetJson(API_ENDPOINTS.LANGUAGE_ALL, {});
};

export const createFetchHistoryRecords = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.HISTORY_RECORDS, params);
};

export const createFetchOrsrSearch = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ORSR_SEARCH, params);
};

export const createFetchTransferRemoveReservation = (itemId) => () => {
    return fetchPostJson(API_ENDPOINTS.TRANSFER_REMOVE_RESERVATION, {}, {
        id_transfer_item: itemId,
    });
};

export const createFetchInventoryAddReservation = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.INVENTORY_ADD_RESERVATION, {}, params);
};

export const createFetchInventoryRemoveReservation = (itemId) => () => {
    return fetchPostJson(API_ENDPOINTS.INVENTORY_REMOVE_RESERVATION, {}, {
        id: itemId,
    });
};

export const createFetchInventoryClasses = () => () => {
    return fetchGetJson(API_ENDPOINTS.INVENTORY_CLASSES);
};

export const createFetchInventoryAddClass = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.INVENTORY_ADD_CLASS, {}, params);
};

export const createFetchInventoryEditClass = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.INVENTORY_EDIT_CLASS, {}, params);
};

export const createFetchInventoryRemoveClass = (classId) => () => {
    return fetchPostJson(API_ENDPOINTS.INVENTORY_REMOVE_CLASS, {}, {
        id: classId,
    });
};

export const createFetchInventoryRefreshClasses = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.INVENTORY_REFRESH_CLASSES, {}, params);
};

export const createFetchNotesSearch = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.NOTES_SEARCH, params);
};

export const createFetchNotesAdd = (params) => () => {
    return fetchPostJson(API_ENDPOINTS.NOTES_ADD, {}, params);
};

export const createFetchNotesEdit = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.NOTES_EDIT, {}, params);
};

export const createFetchNotesRemove = (noteId) => () => {
    return fetchPostJson(API_ENDPOINTS.NOTES_REMOVE, {}, {
        id: noteId
    });
};

export const createFetchInvoiceAddProforma = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.INVOICE_ADD_PROFORMA, {}, params);
};

export const createFetchInvoiceTypes = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.INVOICE_TYPES, params);
};

export const createFetchProductTypes = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.PRODUCT_TYPES, params);
};

export const createFetchTaxRates = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.TAX_RATES, params);
};

export const createFetchFuelTypes = () => () => {
    return fetchGetJson(API_ENDPOINTS.VEHICLE_FUEL_TYPES);
};

export const createFetchZebraPrintProductLabel = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ZEBRA_PRINT_PRODUCT_LABEL, {}, params);
};

export const createFetchAnalysisTypes = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ANALYSIS_TYPES, params);
};

export const createFetchAnalysisGenerate = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ANALYSIS_GENERATE, {}, params);
};

export const createFetchVehicleAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.VEHICLE_ALL, params);
};

export const createFetchVehicleAdd = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.VEHICLE_ADD, {}, formParams);
};

export const createFetchVehicleEdit = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.VEHICLE_EDIT, {}, formParams);
};

export const createFetchVehicleRemove = (vehicleId) => () => {
    return fetchPostJson(API_ENDPOINTS.VEHICLE_REMOVE, {}, {id: vehicleId});
};

export const createFetchEventAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.EVENT_ALL, params);
};

export const createFetchEventDetails = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.EVENT_DETAILS, params);
};

export const createFetchEventAdd = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.EVENT_ADD, {}, formParams);
};

export const createFetchEventEdit = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.EVENT_EDIT, {}, formParams);
};

export const createFetchEventEditDescription = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.EVENT_EDIT_DESCRIPTION, {}, formParams);
};

export const createFetchEventRemove = (eventId) => () => {
    return fetchPostJson(API_ENDPOINTS.EVENT_REMOVE, {}, {id: eventId});
};

export const createFetchEventSend = (eventId) => () => {
    return fetchPostJson(API_ENDPOINTS.EVENT_SEND, {}, {id: eventId});
};

export const createFetchEventTypes = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.EVENT_TYPES, params);
};

export const createFetchEventAddType = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.EVENT_ADD_TYPE, {}, formParams);
};

export const createFetchEventEditType = () => (formParams) => {
    return fetchPostJson(API_ENDPOINTS.EVENT_EDIT_TYPE, {}, formParams);
};

export const createFetchEventRemoveType = (eventTypeId) => () => {
    return fetchPostJson(API_ENDPOINTS.EVENT_REMOVE_TYPE, {}, {id: eventTypeId});
};

export const createFetchAlertAll = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ALERT_ALL, params);
};

export const createFetchAlertTypes = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ALERT_TYPES, params);
};

export const createFetchAlertAck = (alertId, doExec) => () => {
    return fetchPostJson(API_ENDPOINTS.ALERT_ACK, {}, {id: alertId, exec: doExec});
};

export const createFetchAllegroCategories = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ALLEGRO_CATEGORIES, params);
};

export const createFetchAllegroSubtree = () => (params) => {
    return fetchGetJson(API_ENDPOINTS.ALLEGRO_SUBTREE, params);
}

export const createFetchAllegroParameters = ()  => (params) => {
    return fetchGetJson(API_ENDPOINTS.ALLEGRO_PARAMETERS, params);
};

export const createFetchAllegroColumns = ()  => () => {
    return fetchGetJson(API_ENDPOINTS.ALLEGRO_COLUMNS);
};

export const createFetchAllegroSetupParameter = () => (params) => {
    return fetchPostJson(API_ENDPOINTS.ALLEGRO_SETUP_PARAMETER, {}, params);
};