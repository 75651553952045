import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../locationProvider/locationHOC';
import DateTime from '../general/DateTime';
import {EventObj} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import { Button, notification } from 'antd';
import EventRemove from './EventRemove';
import EventEdit from './EventEdit';
import { stopPropagation } from '../../lib/interaction';
import { GLOBAL_DATA } from '../../constants/globalData';
import { createFetchEventAll, createFetchEventEditDescription } from '../../backend/apiCalls';
import InputTextArea from '../general/InputTextArea';
import withDataHOC from '../dataProvider/withDataHOC';
import EventAdd from './EventAdd';
import EventCopy from './EventCopy';

/**
 * @dusan
 */

class EventsList extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.number.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            limit: 10,
            selectedEventId: null
        };
    }

    componentDidMount() {
        this.fetchEvents(10);
    }

    componentDidUpdate(prevProps) {
        const {customerId} = this.props;
        const {customerId: prevCustomerId} = prevProps;

        if(customerId != prevCustomerId)
            this.fetchEvents(10);
    }

    fetchEvents = (newLimit) => {
        const {customerId} = this.props;
        const {limit} = this.state;
        const limitChecked = newLimit != null ? newLimit : limit;
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(
            createFetchEventAll(),
            {
                id_customer: customerId,
                limit: limitChecked,
            },
            (result) => {
                this.setState({
                    events: result,
                    limit: limitChecked,
                    selectedEventId: null
                });
            }
        );
    }

    onSelect = (evId) => {
        this.setState({selectedEventId: evId});
    }

    increaseLimit = () => {
        const {limit} = this.state;
        const newLimit = limit + 20;
        this.fetchEvents(newLimit);
    }

    render() {
        const {customerId} = this.props;
        const {events, selectedEventId, limit} = this.state;
        const limitChecked = limit != null ? limit : 10;
        return <div>
            <EventAdd
                customerId={customerId}
                reload={this.fetchEvents}
            />
            <div>
                { events != null && events.length > 0 ? 
                    events.slice(0, limitChecked + 1).map((ev) => {
                        return <EventTileWrapped
                            key={ev.id}
                            data={ev}
                            reload={this.fetchEvents}
                            onSelect={this.onSelect}
                            selectedEventId={selectedEventId}
                        />
                    })
                    : <h4 className="py-3"><Trans>Neevidujeme žiadne eventy</Trans></h4>
                }
                { events != null && events.length == limitChecked ? 
                    <Button
                        className="m-2 full-size-width"
                        type="primary"
                        icon="double-right"
                        onClick={this.increaseLimit}
                    >
                        <Trans>Zobraziť staršie</Trans>
                    </Button> : null
                }
            </div>
        </div>;
    }

}


class EventTile extends React.PureComponent {
    static propTypes = {
        data: EventObj.isRequired,
        reload: PropTypes.func.isRequired,
        selectedEventId: PropTypes.number,
        onSelect: PropTypes.func.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpened: false,
            description: undefined
        };
    }

    onMouseMove = (ev) => {
        const {data, onSelect, selectedEventId} = this.props;
        stopPropagation(ev);
        if(data != null && selectedEventId != data.id)
            onSelect(data.id);
    }

    onMouseLeave = (ev) => {
        const {data, selectedEventId, onSelect} = this.props;
        stopPropagation(ev);
        if(data != null && selectedEventId == data.id)
            onSelect(null);
    }

    onOpen = () => {
        const {data} = this.props;
        this.setState({
            isOpened: true,
            description: data.description,
        });
    }

    onClose = () => {
        this.setState({
            isOpened: false,
            description: undefined,
        });
    }

    onChange = (newDesc) => {
        this.setState({description: newDesc});
    }

    onMsgKeyDown = (ev) => {
        if(ev.keyCode === 13 && ev.ctrlKey)
            this.onSave();
    }

    onSave = () => {
        const {reload, data} = this.props;
        const {description} = this.state;
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(
            createFetchEventEditDescription(),
            {
                id: data.id,
                description: description,
            },
            () => {
                this.setState({
                    isOpened: false,
                    description: undefined
                });
            },
            reload,
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    }
   
    render() {
        const {data, reload, selectedEventId} = this.props;
        const {isOpened, description} = this.state;
        const isSelected = (selectedEventId == data.id);

        return <div 
            className={"px-1 py-2 full-size-width" + (isSelected ? ' event-tile-selected' : ' event-tyle')}
            onMouseMove={this.onMouseMove}
            onMouseLeave={this.onMouseLeave}
        >
            <div className="d-flex table-subdata">
                <DateTime timeString={data.start_at}/>
                <span className="ml-2 font-weight-bold">
                    {data.type + (data.title != null ? ' - ' + data.title : '')}
                </span>
                { data.vehicle != null ? 
                    <span className="ml-2">{'(' + data.vehicle + ')'}</span>
                    : null
                }
                { isSelected && !isOpened ?
                    <EventEdit
                        eventObj={data}
                        reload={reload}
                        type="link"
                    />
                    : null
                }
                { isSelected && !isOpened ?
                    <EventCopy
                        eventObj={data}
                        reload={reload}
                        type="link"
                    />
                    : null
                }
                { isSelected && !isOpened?
                    <EventRemove
                        eventObj={data}
                        reload={reload}
                        type="link"
                    />
                    : null
                }
            </div>
            <div className="px-2 pt-2 font-italic table-subdata">
                { data.participants != null ? 
                    data.participants.map(part => {
                        return part.name + ' ' + part.surname + ' (' + part.customer + ')'          
                    }).join(', ')
                    : null
                }
            </div>
            { data.place != null ? 
                <div className="px-2 pt-2 table-subdata">
                    <span className="mr-2"><Trans>Miesto:</Trans></span>
                    {data.place}
                </div>
                : null
            }
            <div className="px-2 pt-2 d-flex align-items-end">
                { isOpened ? 
                    <InputTextArea
                        value={description}
                        changeImmediately={true}
                        onChange={this.onChange}
                        onKeyDown={this.onMsgKeyDown}
                    />
                    : <pre>{data.description}</pre>
                }
                { isSelected && !isOpened ? 
                    <Button
                        size="small"
                        icon="edit"
                        className="ml-2"
                        onClick={this.onOpen}
                    />
                    : null
                }
            </div>
            { isOpened ? 
                <div className="px-2 d-flex justify-content-end">
                    <Button
                        size="small"
                        className="m-1"
                        onClick={this.onClose}
                    >
                        <Trans>Zrušiť</Trans>
                    </Button>
                    <Button
                        size="small"
                        type="primary"
                        className="m-1"
                        onClick={this.onSave}
                    >
                        <Trans>Uložiť</Trans>
                    </Button>
                </div>
                : null
            }
        </div>;
    }
}

const EventTileWrapped = withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(EventTile);

export default locationHOC(withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(EventsList));
