import React from 'react';
import PropTypes from 'prop-types';
import {locationHOC} from '../locationProvider/locationHOC';
import { getQueryParam, navigateToParametrized} from '../../lib/url';
import {Trans, t} from '@lingui/macro';
import {Dropdown, Menu} from 'antd';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Language} from '../../constants/propTypesDefinitions';
import withDataHOC from '../dataProvider/withDataHOC';
import { QUERY_PARAMS } from '../../constants/navigation';
import { withPrefix } from 'gatsby';

/**
 * @dusan
 */

class LocaleSelect extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        [GLOBAL_DATA.LANGUAGES]: PropTypes.arrayOf(Language.isRequired),
    };

    render() {
        const {location, [GLOBAL_DATA.LANGUAGES]: languages} = this.props;
        const languagesChecked = languages != null && Array.isArray(languages) ? languages.filter(l => l.is_allowed) : [];
        const currentLang = getQueryParam(location, QUERY_PARAMS.LANG);

        // use icons from wikipedia.org
        const langObj = languagesChecked.find(l => l.id == currentLang);


        return <Dropdown
            placement="bottomRight"
            overlay={
                <Menu>
                    { languagesChecked.map((lang) => {
                        const img = withPrefix(`/images/locale/${lang.id}.png`);
                        return <Menu.Item key={lang.id}>
                            <div 
                                className="d-flex align-items-center"
                                onClick={() => {
                                    navigateToParametrized(location, null, {
                                        [QUERY_PARAMS.LANG]: lang.id
                                    })
                                }}
                            >
                                <img 
                                    src={img} 
                                    height="18px" 
                                    className="mr-2" 
                                    alt={lang.name}
                                />
                                {lang.name}
                            </div>
                        </Menu.Item>
                    })}
                </Menu>
            }
        >   
            { langObj != null ?
                <img 
                    src={withPrefix(`/images/locale/${langObj.id}.png`)} 
                    className="m-2"
                    height="18px"
                    alt={langObj.name}
                />
                :
                <div></div>
            }
        </Dropdown>;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.LANGUAGES])(LocaleSelect));