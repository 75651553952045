// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {QUERY_PARAMS, IS_MOBILE_COOKIE, TRUE_RIGHTS_COOKIE, CONSENT_COOKIE} from '../../constants/navigation'
import {ASYNC_STATE} from '../../constants/AsyncState'
import {navigateToParametrized, getQueryParam, getRelativePath} from '../../lib/url';
import {ROUTES, RIGHTS_COOKIE} from '../../constants/navigation';
import {RIGHTS} from '../../constants/Rights';
import { createFetchSessionSettings, createUserLogout } from '../../backend/apiCalls';
import { ERROR_CODES } from '../../constants/errorCodes';
import { withCookies } from 'react-cookie';

/**
 * @fero
 */

export const SESSION_ATTRIBUTES = {
    RIGHTS: 'rights',
    TRUE_RIGHTS: 'trueRights',
    RELOAD_RIGHTS: 'reloadRights',
    TRY_RIGHTS: 'tryRights',
    IS_MOBILE: 'isMobile',
    IS_READY: 'sessionReady',
    DISABLE_MOBILE: 'disableMobile',
    SETTINGS: 'settings',
    LOGOUT: 'logout',
    GTAG: 'gtag',
    SETUP_GTAG: 'setupGtag',
};

//makes call to server on mount so session is created if it was not before
//sets rights from cookies
//sets defaults
class SessionProvider extends React.PureComponent {
    static propTypes = {
        onSetup: PropTypes.func,
        locale: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.gtag = this.gtag.bind(this);

        this.state = {
            rights: null,
            trueRights: null,
            isMobile: false,
            isMobileDisabled: false,
            reloadRights: this.reloadRights,
            disableMobile: this.disableMobile,
            tryRights: this.tryRights,
            sessionReady: false,
            sessionError: null,
            settings: {},
            logout: this.logout,
            gtag: this.gtag,
            setupGtag: this.setupGtag,
        }
    }

    componentDidMount() {
        this.setDefaults();
    }

    componentDidUpdate(prevProps) {
        const {location, locale} = this.props;
        const {locale: prevLocale} = prevProps;
        const {rights, sessionReady, settings} = this.state;
        const cookieRights = this.getRightsFromCookie();

        if(sessionReady)
        {
            if(locale != prevLocale && locale != settings.locale)
            {
                // reload session with new locale
                console.log('new locale detected');
                this.setDefaults();
            }
            else if(rights != cookieRights)
            {
                // change of rights, maybe login/logout, session expiry
                console.log('inconsistent session rights');
                this.setDefaults(null, null, true); // reload locale
            }
            else if(rights >= RIGHTS.MARKETING)
            {
                // check if all defaults are set
                const customerId = getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER);
                const returnTo = getQueryParam(location, QUERY_PARAMS.RETURN_TO);
                if(customerId == null && returnTo == null)
                {
                    navigateToParametrized(location, null, {
                        [QUERY_PARAMS.ID_CUSTOMER]: settings.id_default_customer,
                        [QUERY_PARAMS.ID_REF_CUSTOMER]: settings.id_default_ref_customer,
                        [QUERY_PARAMS.LANG]: settings.locale,
                    });
                }
            }
        }
    }

    // returns current rights from cookies
    getRightsFromCookie = () => {
        const {cookies} = this.props;
        return cookies.get(RIGHTS_COOKIE);
    }

    // returns true rights from cookies
    getTrueRightsFromCookie = () => {
        const {cookies} = this.props;
        return cookies.get(TRUE_RIGHTS_COOKIE);
    }

    // returns isMobile flag from cookies
    getIsMobileFromCookie = () => {
        const {cookies} = this.props;
        return (cookies.get(IS_MOBILE_COOKIE) == 1);
    }

    logout = () => {
        this.setState({sessionReady: false});
        createUserLogout()().then((result) => {
            const {location} = this.props;
            navigateToParametrized(location, ROUTES.HOME, null);
            this.setState({sessionReady: true, rights: RIGHTS.UNREGISTERED});
        });
    }

    // reloads session when rights are non-consistent
    reloadRights = (path, queryParams, resetLocale) => {
        const {sessionReady} = this.state;
        if(sessionReady)
            this.setDefaults(path, queryParams, resetLocale);
    }

    gtag() {
        const {settings, rights} = this.state;
        if(window == null)
            return;

        if(window.dataLayer == null)
            window.dataLayer = [];

        if(rights < RIGHTS.MARKETING && settings != null && settings.google_analytics_code != null)
            window.dataLayer.push(arguments);
    };

    setupGtag = () => {
        const {cookies} = this.props;
        const {settings} = this.state;

        if(settings == null || settings.google_analytics_code == null)
            return;

        const cookieConsentValue = cookies.get(CONSENT_COOKIE);
        const consent = cookieConsentValue == 'yes' ? 'granted' : 'denied';

        this.gtag('consent', 'default', {
            'ad_user_data': consent,
            'ad_personalization': consent,
            'ad_storage': consent,
            'analytics_storage': consent,
        });
        this.gtag('js', new Date());
        this.gtag('config', settings.google_analytics_code);
    }

    //when page is first loaded this function must be called
    //calls /settings/session/ endpoint, should be used when session lock is true so no other data are loaded and thus
    //only 1 session is created on server(endpoint is called even though defaults are set)
    //sets defaults to query part of path, default customer id and default ref customer are set for dealer(if not
    //defined in path already)
    //navigateToParametrized mechanics is called every time(whether customer defaults need to be set), thatks to this
    //defaults for path are set by navigateToParametrized mechanics.
    setDefaults = (returnPath, returnQueryParams, resetLocale) => {
        const { location, locale, onSetup} = this.props;
        const {isMobileDisabled} = this.state;
        const apiKey = getQueryParam(location, QUERY_PARAMS.API_KEY);
        const apiKeyChecked = apiKey != null && apiKey != '' ? apiKey : undefined;
        const newLocale = resetLocale ? null : locale;
        this.setState({sessionReady: false});
        console.log('reloading session...');

        const sessionArgs = {
            api_key: apiKeyChecked,
            locale: newLocale,
        };

        createFetchSessionSettings()(sessionArgs).then((response) => {
            const {location} = this.props;
            const rights = this.getRightsFromCookie();
            const trueRights = this.getTrueRightsFromCookie();
            const isMobile = this.getIsMobileFromCookie() && !isMobileDisabled;

            this.setState({
                rights: rights,
                trueRights: trueRights,
                isMobile: isMobile,
            });

            if (response.status >= 400 || response.asyncState != ASYNC_STATE.SUCCEEDED || response.result == null || rights == null) {
                // something went wrong
                const error = response.result != null ? response.result : null;
                const code = error != null && error.code != null ? error.code : null;

                if(code == ERROR_CODES.ACCESS_DENIED)
                {
                    // access denied, go the login page
                    const returnTo = getRelativePath(location.pathname);
                    navigateToParametrized(location, ROUTES.LOGIN, {
                        [QUERY_PARAMS.RETURN_TO]: returnTo != ROUTES.LOGIN ? returnTo : ROUTES.HOME,
                        [QUERY_PARAMS.API_KEY]: undefined
                    }, true);

                    this.setState({sessionReady: true});
                }
                else
                {
                    // another error, go to error page
                    this.setState({sessionError: error});
                    navigateToParametrized(location, ROUTES.ERROR, {[QUERY_PARAMS.API_KEY]: undefined}, true);
                }
            }
            else 
            {
                // everything seems ok
                this.setState({
                    sessionError: null,
                    settings: response.result,
                });

                if(onSetup != null)
                    onSetup(response.result);

                // set defaults, navigate to target page
                const returnTo = returnPath != null ? returnPath : getQueryParam(location, QUERY_PARAMS.RETURN_TO);
                const returnParams = returnQueryParams != null ? returnQueryParams : {};
                const result = response.result != null ? response.result : {};

                if (rights >= RIGHTS.CUSTOMER) 
                {
                    // set default id_customer and id_ref_customer
                    const customerId = getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER);
                    const refCustomerId = getQueryParam(location, QUERY_PARAMS.ID_REF_CUSTOMER);

                    navigateToParametrized(location, returnTo, {
                        [QUERY_PARAMS.API_KEY]: undefined,
                        [QUERY_PARAMS.LANG]: result.locale,
                        [QUERY_PARAMS.RETURN_TO]: undefined,
                        [QUERY_PARAMS.ID_CUSTOMER]: customerId != null ? customerId : result.id_default_customer,
                        [QUERY_PARAMS.ID_REF_CUSTOMER]: rights >= RIGHTS.WORKER ? 
                            (refCustomerId != null ? refCustomerId : result.id_default_ref_customer) 
                            : undefined,
                        ...returnParams
                    }, true);
                } 
                else 
                {
                    // only clear API key and id_customer selection
                    navigateToParametrized(location, returnTo, {
                        [QUERY_PARAMS.API_KEY]: undefined,
                        [QUERY_PARAMS.LANG]: result.locale,
                        [QUERY_PARAMS.RETURN_TO]: undefined,
                        [QUERY_PARAMS.ID_CUSTOMER]: undefined,
                        [QUERY_PARAMS.ID_REF_CUSTOMER]: undefined,
                        ...returnParams
                    }, true);
                }

                // unlock session - DataProvider will start to fetch data after this
                this.setState({sessionReady: true});
            }
        });
    }

    //clears isMobile flag
    disableMobile = () => {
        const {location} = this.props;
        this.setState({ 
            isMobile: false,
            isMobileDisabled: true,
        });
        navigateToParametrized(location, null, {});
    }

    // downgrades current rights
    tryRights = (newRights) => {
        const {trueRights} = this.state;
        const {cookies} = this.props;

        if(newRights <= trueRights && newRights > RIGHTS.UNREGISTERED)
        {
            cookies.set(RIGHTS_COOKIE, newRights);
            this.setState({rights: newRights});
        }
    }

    render() {
        const {children} = this.props;
        return <SessionContext.Provider value={this.state}>
            {children}
        </SessionContext.Provider>;
    }

}

export default withCookies(SessionProvider);

export const SessionContext = React.createContext({
    rights: RIGHTS.UNREGISTERED,
    reloadRights: () => {
        console.warning('default for reloadRights')
    },
    logout: () => {
        console.warning('default for logout')
    },
    trueRights: RIGHTS.UNREGISTERED,
    tryRights: () => {
        console.warning('default for tryRights')
    },
    isMobile: false,
    disableMobile: () => {
        console.warning('default for disableMobile')
    },
    sessionReady: false,
    sessionError: null,
    settings: {},
    gtag: () => {
        console.warning('default for gtag')
    },
    setupGtag: () => {
        console.warning('default for setupGtag')
    },
});