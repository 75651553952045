import React from 'react';
import Logout from '../../login/Logout';
import withDataHOC from '../../dataProvider/withDataHOC';
import Account from './Account';
import {Dropdown, Icon, Menu} from 'antd';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {UserInfo} from '../../../constants/propTypesDefinitions';
import {formatUserInfoName} from '../../../lib/formatting';
import Dashboard from './Dashboard';
import { RIGHTS } from '../../../constants/Rights';
import Assignments from './Assignments';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import ChangeRights from '../../login/ChangeRights';

/**
 * @fero
 */

class UserDropDown extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.ACCOUNT_INFO]: UserInfo.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedRights: null
        };
    }

    render() {
        const {
            [GLOBAL_DATA.ACCOUNT_INFO]: userInfo, 
            [SESSION_ATTRIBUTES.RIGHTS]: rights,
            [SESSION_ATTRIBUTES.TRUE_RIGHTS]: trueRights,
        } = this.props;
        const {selectedRights} = this.state;

        let menuItems = [];

        if(trueRights >= RIGHTS.WHOLESALE_CUSTOMER)
        {
            menuItems.push(
                <Menu.Item key="rights" className="pz-3 py-2 list-item">
                    <ChangeRights
                        onFinish={(newRights) => this.setState({selectedRights: newRights})}
                    />
                </Menu.Item>
            );
        }

        if(rights >= RIGHTS.MARKETING)
        {
            menuItems.push(
                <Menu.Item key="dash" className="pz-3 py-2 list-item">
                    <Dashboard/>
                </Menu.Item>,
                <Menu.Item key="assign" className="pz-3 py-2 list-item">
                    <Assignments/>
                </Menu.Item>
            );
        }

        menuItems.push(
            <Menu.Item key="account" className="pz-3 py-2 list-item">
                <Account/>
            </Menu.Item>,
            <Menu.Item key="logout" className="pz-3 py-2 list-item">
                <Logout/>
            </Menu.Item>
        );

        return <Dropdown 
            overlay={
                <Menu>
                    {menuItems}
                </Menu>
            } 
            trigger={['click']} 
            placement="bottomRight"
        >
            <div className="m-2 p-2 ant-btn normal-line-height ant-btn-primary cart-button d-flex align-items-center">
                <Icon className="pr-3" type="user" style={{fontSize: '1.5rem'}}/>
                <div>
                    <div>{formatUserInfoName(userInfo)}</div>
                    <div>{selectedRights != null ? selectedRights.name : userInfo.role}</div>
                </div>
            </div>
        </Dropdown>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RIGHTS, SESSION_ATTRIBUTES.TRUE_RIGHTS])(
    withDataHOC([GLOBAL_DATA.ACCOUNT_INFO])(UserDropDown));